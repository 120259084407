import { useFirebaseDownloader, BufferingState, DownloaderHandle } from "../buffering";
import { usePromise } from "../utils";
import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { VoiceData, Entity } from "../metadata";
import React from "react";

type Peaks = number[];

export function buildVoiceAudioPath(voice: VoiceData): string {
    const songId = voice.songRef?.id as string;
    const userId = voice.createdBy.uid;
    const attemptId = voice.attemptId;

    return `${songId}/voice-${userId}-${attemptId}.audio`;
}

export function buildBackingTrackPath(songId: string): string {
    return `${songId}/soundtrack`;
}

export interface AudioBufferHandle {
    buffer?: AudioBuffer | null;
    downloader: DownloaderHandle;
}

export function useFirebaseAudioBuffer(audioCtx: AudioContext, path: string | null, autoDownload: boolean = true): AudioBufferHandle {
    const downloader = useFirebaseDownloader(path, "arraybuffer", autoDownload);
    
    const [buffer, error] = usePromise(async () => {
        if (!downloader.data) return null;
        return await audioCtx.decodeAudioData(downloader.data as ArrayBuffer);
    }, [downloader.data]);

    return { downloader, buffer };
}

export function computePeaks(buffer: AudioBuffer, length: number, first?: number, last?: number) {
    const channels = buffer.numberOfChannels;
    const mergedPeaks = [];
    mergedPeaks[2 * (length - 1)] = 0;
    mergedPeaks[2 * (length - 1) + 1] = 0;

    first = first || 0;
    last = last || length - 1;

    const sampleSize = buffer.length / length;
    const sampleStep = ~~(sampleSize / 10) || 1;

    let c;
    for (c = 0; c < channels; c++) {
        const chan = buffer.getChannelData(c);
        let i;

        for (i = first; i <= last; i++) {
            const start = ~~(i * sampleSize);
            const end = ~~(start + sampleSize);
            let min = 0;
            let max = 0;
            let j;

            for (j = start; j < end; j += sampleStep) {
                const value = chan[j];

                if (value > max) {
                    max = value;
                }

                if (value < min) {
                    min = value;
                }
            }

            if (c == 0 || max > mergedPeaks[2 * i]) {
                mergedPeaks[2 * i] = max;
            }

            if (c == 0 || min < mergedPeaks[2 * i + 1]) {
                mergedPeaks[2 * i + 1] = min;
            }
        }
    }

    return mergedPeaks;
}

export function drawPeaks(drawCtx: CanvasRenderingContext2D, peaks: number[], width: number, params: { height: number, pixelRatio: number, barWidth: number, normalize: boolean }, start?: number, end?: number) {

    start = start || 0;
    end = end || peaks.length - 1;

    // Bar wave draws the bottom only as a reflection of the top,
    // so we don't need negative values
    const hasMinVals = [].some.call(peaks, val => val < 0);
    // Skip every other value if there are negatives.
    const peakIndexScale = hasMinVals ? 2 : 1;

    // A half-pixel offset makes lines crisp
    const height = params.height * params.pixelRatio;
    const offsetY = 0;
    const halfH = height / 2;
    const length = peaks.length / peakIndexScale;
    const bar = params.barWidth * params.pixelRatio;
    const gap = Math.max(params.pixelRatio, ~~(bar / 2));
    const step = bar + gap;

    let absmax = 1;
    if (params.normalize) {
        const max = Math.max(...peaks);
        const min = Math.min(...peaks);
        absmax = -min > max ? -min : max;
    }

    const scale = length / width;
    let i;

    const halfPixel = 0.5 / params.pixelRatio;

    drawCtx.fillStyle = '#999999';

    for (i = (start / scale); i < (end / scale); i += step) {
        const peak = peaks[Math.floor(i * scale * peakIndexScale)] || 0;
        const h = Math.round(peak / absmax * halfH);

        const rx = i + halfPixel;
        const ry = halfH - h + offsetY;
        const rw = bar + halfPixel;
        const rh = h * 2;

        drawCtx.fillRect(rx, ry, rw, rh);
    }

}


interface WaveFormProps {
    buffer?: AudioBuffer;
    position?: number;
}

export function WaveForm(props: WaveFormProps) {
    const canvas = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (!props.buffer) return;

        const peaks = computePeaks(props.buffer, 150);

        const canvasCtx = canvas.current?.getContext("2d");
        const width = canvas.current?.width;
        const height = canvas.current?.height;

        console.log({ canvasCtx, width, height });

        if (!canvasCtx || !peaks || !width || !height) return;

        canvasCtx.fillStyle = '#faf3fa';
        canvasCtx.fillRect(0, 0, width, height);

        console.log(["drawing peaks", peaks]);
        drawPeaks(canvasCtx, peaks, width, { height: height, pixelRatio: 1, barWidth: 3, normalize: false });
    }, [canvas.current, props.buffer]);

    return <canvas ref={canvas} style={{ flexGrow: 1 }} width={800} height={100} />
}

export interface MultiTrackBranch {
    key: string;
    buffer: AudioBuffer;
    drift: number;
    volume: number;
    voice: Entity<VoiceData> | null;
}

interface ArmedTrack {
    disarm: () => void;
}

function armTrack(context: BaseAudioContext, track: MultiTrackBranch, clockStart: number, position: number): ArmedTrack {
    const sourceNode = context.createBufferSource();
    sourceNode.buffer = track.buffer;

    const gainNode = context.createGain();
    gainNode.gain.value = track.volume;

    sourceNode.connect(gainNode);
    gainNode.connect(context.destination);

    const offset = position + ((track.drift || 0) / 1000);
    sourceNode.start(clockStart, offset);

    return {
        disarm: () => {
            sourceNode.stop();
            sourceNode.disconnect();
        }
    };
}

export interface ArmedClock {
    elapsed: number;
    position: number;
    running: boolean;
}

export interface ArmedMix {
    getClock: () => ArmedClock;
    disarm: () => void;
}

export function armMix(context: BaseAudioContext, tracks: MultiTrackBranch[], initialPosition: number): ArmedMix {
    const clockStart = context.currentTime + .5;
    const armed = tracks.map(t => armTrack(context, t, clockStart, initialPosition));

    const getClock = (): ArmedClock => {
        const elapsed = context.currentTime - clockStart;
        const position = initialPosition + elapsed;
        const running = (context.state == "running");
        return { elapsed, position, running };
    };

    return {
        getClock,
        disarm: () => armed.forEach(t => t.disarm()),
    };
}

export interface MixPlayer {
    getClock: () => ArmedClock | undefined;
    play: () => void;
    pause: () => void;
}

export function useMixPlayer(audioCtx: AudioContext, mix: Record<string, MultiTrackBranch>, initialPosition: number): MixPlayer {
    const [armed, setArmed] = useState<ArmedMix>();

    useEffect(() => {
        audioCtx.suspend();
        
        const armed = armMix(audioCtx, Object.values(mix), initialPosition);
        setArmed(armed);

        return () => armed.disarm();
    }, [audioCtx, mix, initialPosition]);

    return { 
        play: () => audioCtx.resume(),
        pause: () => audioCtx.suspend(),
        getClock: () => armed?.getClock(),
    };
}

/* 
export function useMixRenderer(audioCtx: OfflineAudioContext, mix: Record<string, MultiTrackBranch>): MixRenderer {
    const [armed, setArmed] = useState<ArmedMix>();

    useEffect(() => {
        const armed = armMix(audioCtx, Object.values(mix), 0);
        audioCtx.startRendering().then(data => {
            data.
        })

        
    }, [audioCtx, mix]);

    return { 
        render: () => audioCtx.startRendering(),
        getClock: () => armed?.getClock(),
    };
} */