import React from 'react';
import { Button, Grid, Paper, Box, Typography } from '@material-ui/core';
import { DoubleContentBrick, ImageBlock, Section, TextBlock, TextBlockProps, TripleContentBrick, FooterSection } from '../layout';

interface JoinCardProps extends TextBlockProps {}

function JoinCard(props: JoinCardProps) {
    return (
        <Paper style={{ flexGrow: 1, display: "flex" }}>
            <TextBlock
                padding={5}
                titleVariant="h3"
                justifyContent="space-between"
                {...props}
            />
        </Paper>
    );
}

export function HomePage() {
    return (
        <Box display="flex" flexDirection="column" alignItems="stretch" flexGrow="1">
            <DoubleContentBrick
                first={
                    <TextBlock
                        marginY={3}
                        pretitle="OpenChoir"
                        title="Let's sing together despite the distance"
                        subtitle="OpenChoir is a community where musicians and singers can join their voices in virtual interpretations, regardless of where they are"
                    />
                }
                second={
                    <ImageBlock
                        imgStyles={{ maxWidth: "100%" }}
                        source="illus2.png"
                    />
                }
                variant="lightblueTop"
            />
            <DoubleContentBrick
                first={<TextBlock
                    title="Sing from Home"
                    titleVariant="h3"
                    subtitle="Select a song and join the choir by recording your voice over a pre-recorder conductor's video. You just need a microphone, a webcam and Google Chrome."
                />}
                second={<ImageBlock
                    source="sing.png"
                    justifyContent="center"
                    imgStyles={{ maxHeight: 400, maxWidth: "100%" }}
                />}
                marginY={6}
                reverse={true}
            />

            <DoubleContentBrick
                first={<TextBlock
                    alignItems="center"
                    title="Mix Audio & Video on the Cloud"
                    titleVariant="h3"
                    subtitle="Use our web editor to mix the audio and create the video grid from a friendly interface, without downloading any tools."
                />}
                second={<ImageBlock
                    source="videomix.png"
                    justifyContent="center"
                    imgStyles={{ maxWidth: "100%" }}
                />}
                marginY={6}
            />

            <DoubleContentBrick
                first={<TextBlock
                    title="Share on your Social Network"
                    titleVariant="h3"
                    subtitle="When you're happy with the result, upload your video on YouTube or any other of your social networks."
                />}
                second={<ImageBlock
                    source="videoshot2.png"
                    justifyContent="center"
                    imgStyles={{ maxWidth: "100%" }}
                    href="https://youtu.be/FC_cJtUNlg4"
                />}
                marginY={6}
                reverse={true}
            />
            <Section variant="lightblueBottom">
                <Grid item xs={12}>
                    <TripleContentBrick
                        first={<JoinCard
                            padding={5}
                            title="For Singers"
                            titleVariant="h4"
                            subtitle="browse the catalog of open songs and record your voice right away, fill out our form to join our waiting list"
                            cta={<Button size="large" href="https://forms.gle/bRZX1cU2Ktg7ELoE8" variant="contained" color="secondary">Join</Button>}
                        />}
                        second={<JoinCard
                            padding={5}
                            title="For Conductors"
                            titleVariant="h4"
                            subtitle="if you want to use OpenChoir for your group, fill out our form to join our waiting list"
                            cta={<Button size="large" href="https://forms.gle/bRZX1cU2Ktg7ELoE8" variant="contained" color="secondary">Join</Button>}
                        />}
                        third={<JoinCard
                            padding={5}
                            title="For Schools"
                            titleVariant="h4"
                            subtitle="if you want to use OpenChoir in your school, fill out our form to join our waiting list"
                            cta={<Button size="large" href="https://forms.gle/bRZX1cU2Ktg7ELoE8" variant="contained" color="secondary">Join</Button>}
                        />}
                        marginY={6}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DoubleContentBrick
                        reverse={true}
                        first={<TextBlock
                            marginY={14}
                            alignItems="flex-start"
                            title="Is this for free?"
                            subtitle="OpenChoir is not for profit, we are just a group of people who believe that music will help us get through these moments of social distancing. To keep the project going, we're looking for sponsorship."
                            cta={<Button variant="contained" color="secondary" size="large" href="/about">About Us</Button>}
                        />}
                        second={<ImageBlock
                            imgStyles={{ maxHeight: 500, maxWidth: "100%" }}
                            source="illus5.png"
                        />}
                    />
                </Grid>
            </Section>
            <FooterSection />
        </Box>
    );
}