import React, { MutableRefObject } from "react";
import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useAnimationLoop, useInterval } from "../utils";
import { LinearProgress, Box, Typography } from "@material-ui/core";
import { SongData } from "../metadata";
import { BaseMediaMode, AnyBaseMediaView } from "../playback";
import { useCallForward, CallHolder } from "react-callforward";

export interface BlobAndObjectUrl {
    blob: Blob;
    url: string;
}

export interface RecordingContext {
    stream?: MediaStream | null;
    startRecording: () => void;
    stopRecording: () => void;
    clearOutput: () => void;
    output: BlobAndObjectUrl | null;
    isRecording: boolean;
    startTs?: number;
}

export function useUserMedia(constraints: any): [MediaStream | null | undefined, Error | null | undefined] {
    //const constraints = { audio: true, video: true };
    const [stream, setStream] = useState<MediaStream>();
    const [error, setError] = useState<Error>();

    useEffect(() => {
        if (!constraints) return;

        if (!navigator.mediaDevices) {
            setError(new Error("no media devices available"));
        }

        navigator.mediaDevices.getUserMedia(constraints).then(stream => {
            setStream(stream);
        });
    }, [constraints]);

    return [stream, error];
}

export function useRecorder(stream?: MediaStream | null): RecordingContext {
    const [recorder, setRecorder] = useState<MediaRecorder | null>(null);
    const [output, setOutput] = useState<{ blob: Blob, url: string } | null>(null);
    const [startTs, setStartTs] = useState<number>();
    const tempChunks = useRef<BlobPart[]>([]);

    useEffect(() => {
        if (!stream) return;

        const recorder = new MediaRecorder(stream, { audioBitsPerSecond: 320000, videoBitsPerSecond: 3000000 });
        
        recorder.onstart = () => {
            const now = Date.now();
            setStartTs(now);
        }

        recorder.ondataavailable = (e) => {
            if (!e.data) return;
            if (e.data.size === 0) return;
            tempChunks.current.push(e.data);
        }

        recorder.onstop = (_) => {
            const blob = new Blob(tempChunks.current, { 'type': recorder.mimeType });
            const url = URL.createObjectURL(blob)
            setOutput({ blob, url });
            tempChunks.current = [];
            setStartTs(undefined);
        }

        setRecorder(recorder);
    }, [stream]);

    const onClear = useCallback(() => {
        if (!!output) {
            URL.revokeObjectURL(output.url);
            delete output.blob;
            setOutput(null);
        }
    }, [output]);

    return useMemo(() => ({
        stream,
        startRecording: () => recorder?.start(),
        stopRecording: () => recorder?.stop(),
        clearOutput: onClear,
        output,
        startTs,
        isRecording: !!startTs,
    }), [stream, recorder, output, startTs]);
}


const MIC_ANALYZER_BUFF_SIZE = 2048;

export function MicLevel(props: { stream?: MediaStream | null, height?: number | string }) {
    const analyzer = useRef<AnalyserNode>();
    const data = useRef<Uint8Array>(new Uint8Array(MIC_ANALYZER_BUFF_SIZE));
    const canvas = useRef<HTMLCanvasElement>(null);

    useAnimationLoop((time: number) => {
        analyzer.current?.getByteTimeDomainData(data.current);
        const canvasCtx = canvas.current?.getContext("2d");
        const width = canvas.current?.width;
        const height = canvas.current?.height;

        if (!canvasCtx || !data.current || !width || !height) return;


        canvasCtx.fillStyle = '#faf3fa';
        canvasCtx.fillRect(0, 0, width, height);

        canvasCtx.lineWidth = 2;
        canvasCtx.strokeStyle = '#f50057';

        const sliceWidth = width * 1.0 / MIC_ANALYZER_BUFF_SIZE;
        let x = 0;

        canvasCtx.beginPath();
        for (var i = 0; i < MIC_ANALYZER_BUFF_SIZE; i++) {
            const v = data.current[i] / 128.0;
            const y = v * height / 2;

            if (i === 0)
                canvasCtx.moveTo(x, y);
            else
                canvasCtx.lineTo(x, y);

            x += sliceWidth;
        }

        canvasCtx.lineTo(width, height / 2);
        canvasCtx.stroke();
    });

    useEffect(() => {
        if (!props.stream) return;
        const audioContext = new AudioContext();
        const source = audioContext.createMediaStreamSource(props.stream);
        const node = audioContext.createAnalyser();
        node.fftSize = MIC_ANALYZER_BUFF_SIZE;
        source.connect(node);
        analyzer.current = node;

        return () => {
            source.disconnect();
            node.disconnect();
            analyzer.current = undefined;
        };
    }, [props.stream]);

    return <canvas ref={canvas} style={{ flexGrow: 1, height: props.height }} />
}

export function LivePreview(props: { stream?: MediaStream | null, width?: string | number, height?: string | number }) {
    const videoEl = useRef<HTMLVideoElement | null>(null);

    useEffect(() => {
        if (!!videoEl.current && !!props.stream) {
            videoEl.current.srcObject = props.stream;
            videoEl.current.muted = true;
            videoEl.current.play();
        }
    }, [props.stream, videoEl]);

    return (
        <video ref={videoEl} muted style={{ width: props.width, height: props.height }} />
    );
}