import { Box, Button, Container, Grid, Paper, Slider, Typography, TextField, CircularProgress } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import { AuthWall } from '../auth';
import { VoiceSnapshot } from '../buffering';
import { Background, MainBar } from '../layout';
import { Entity, fetchVoiceListing, SongData, useSongData, VoiceData } from "../metadata";
import { useAnimationLoop, usePromise } from '../utils';
import { VoiceSelectDialog, SectionSubMenu } from './common';
import { buildVoiceAudioPath, MultiTrackBranch, useFirebaseAudioBuffer, useMixPlayer, WaveForm, buildBackingTrackPath, MixPlayer, ArmedClock } from './framework';

import PlayIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import { MediaPlaceHolder } from '../uikit';

interface SongParams {
    song: string;
}

interface VoiceRowProps {
    track: MultiTrackBranch;
    onDriftChange: (trackKey: string, value: number) => void;
    onVolumeChange: (trackKey: string, value: number) => void;
}

function VoiceRow(props: VoiceRowProps) {
    const onDriftChange = useCallback((evt: React.ChangeEvent<{}>, value: number | number[]) => {
        props.onDriftChange(props.track.key, (value as number));
    }, []);

    const onVolumeChange = useCallback((evt: React.ChangeEvent<{}>, value: number | number[]) => {
        props.onVolumeChange(props.track.key, (value as number) / 5);
    }, []);

    const placeholderStyle: React.CSSProperties = { width: "100%", height: 150, objectFit: "contain", backgroundColor: "#000000" };

    return (
        <Grid container spacing={2}>
            <Grid item xs={2}>
                {props.track.voice && <VoiceSnapshot voice={props.track.voice.data} style={{ width: "100%", height: 150, objectFit: "cover" }} />}
                {props.track.key == "backing" && <MediaPlaceHolder style={placeholderStyle} message="Backing Track" />}
            </Grid>
            <Grid item xs={2}>
                <Typography color="textSecondary" gutterBottom>
                    {props.track.voice?.data?.partKey}
                </Typography>
                <Typography variant="caption">drift</Typography>
                <Slider
                    onChangeCommitted={onDriftChange}
                    valueLabelDisplay="auto"
                    defaultValue={props.track.drift}
                    step={100}
                    min={0}
                    max={4000}
                />
                <Typography variant="caption">volume</Typography>
                <Slider
                    onChangeCommitted={onVolumeChange}
                    valueLabelDisplay="auto"
                    defaultValue={props.track.volume}
                    step={1}
                    min={0}
                    max={5}
                />
            </Grid>
            <Grid item xs={8} style={{ display: "flex" }}>
                <WaveForm buffer={props.track.buffer} />
            </Grid>
        </Grid>
    )
}


function useLazyRef<T>(factory: () => T): React.MutableRefObject<T> {
    const lazyRef = useRef<T>();

    if (!lazyRef.current) {
        lazyRef.current = factory();
    }

    return lazyRef as React.MutableRefObject<T>;
}

interface ClockProps {
    player: MixPlayer;
    initialPosition: number;
    duration: number;
    onPositionChange: (value: number) => void;
}

function Controls(props: ClockProps) {
    const [state, setState] = useState<{ position: number, remaining: number, running: boolean }>({
        position: props.initialPosition,
        remaining: props.duration,
        running: false,
    });

    useAnimationLoop(() => {
        const clock = props.player.getClock();
        if (!clock) return;
        const position = Math.round(clock.position * 10) / 10;
        const remaining = Math.round((props.duration - position) * 10) / 10;
        const running = clock.running;
        setState({ position, remaining, running });
    });

    return (
        <Paper>
            <Box padding={2} justifyContent="flex-start">
                <Box display="flex">
                    <Box flexGrow={1}>
                        { !state.running && <Button startIcon={<PlayIcon />} onClick={props.player.play}>play</Button> }
                        { state.running && <Button startIcon={<PauseIcon />} onClick={props.player.pause}>pause</Button> }
                    </Box>
                    <Box>
                        <TextField disabled label="position" value={state.position} />
                        <TextField disabled label="duration" value={props.duration} />
                        <TextField disabled label="remaining" value={state.remaining} />
                    </Box>
                </Box>
                <Slider value={Math.round(state.position)} max={Math.round(props.duration)} onChangeCommitted={(_, value) => props.onPositionChange(value as number)} />
            </Box>
        </Paper>
    );
}

function TrackLoader(props: { allVoices: Entity<VoiceData>[], audioCtx: AudioContext, onTrackLoaded: (track: MultiTrackBranch) => void }) {
    const [selecting, setSelecting] = useState<boolean>(false);
    const [selected, setSelected] = useState<Entity<VoiceData>>();

    const onSelect = useCallback((voice: Entity<VoiceData>) => {
        setSelected(voice);
        setSelecting(false);
    }, []);

    const path = !!selected?.data ? buildVoiceAudioPath(selected.data) : null;
    const download = useFirebaseAudioBuffer(props.audioCtx, path);

    useEffect(() => {
        if (!selected?.data || !download.buffer) return;

        const track: MultiTrackBranch = {
            key: selected.id,
            buffer: download.buffer,
            drift: selected.data.drift,
            volume: 1,
            voice: selected,
        };

        props.onTrackLoaded(track);
    }, [download.buffer]);

    return (
        <>
            <VoiceSelectDialog open={selecting} voices={props.allVoices} onSelect={onSelect} />
            <Button color="primary" variant="contained" disabled={download.downloader.isRunning} onClick={() => setSelecting(true)}>Include Voice in Mix</Button>
        </>
    );
}

type MultiTrack = Record<string, MultiTrackBranch>;

function LoadedPage(props: { songId: string, song: SongData, voices: Entity<VoiceData>[] }) {
    const [mix, setMix] = useState<MultiTrack>({});
    const [initialPosition, setInitialPosition] = useState<number>(0);
    const context = useRef<AudioContext>(new AudioContext());

    const path = buildBackingTrackPath(props.songId);
    const backingTrack = useFirebaseAudioBuffer(context.current, path);

    useEffect(() => {
        if (!backingTrack.buffer) return;

        const track: MultiTrackBranch = {
            key: "backing",
            buffer: backingTrack.buffer,
            drift: 0,
            volume: 1,
            voice: null,
        };

        setMix(previous => ({
            ...previous,
            backing: track
        }));
    }, [backingTrack.buffer]);

    const duration = Math.round(Math.max(...Object.values(mix).map(t => t.buffer.duration)));

    const onTrackLoaded = useCallback((track: MultiTrackBranch) => {
        setMix(previous => ({
            ...previous,
            [track.key]: track,
        }));
    }, []);

    const onDriftChange = useCallback((trackKey: string, value: number) => {
        setMix(previous => ({
            ...previous,
            [trackKey]: {
                ...previous[trackKey],
                drift: value,
            }
        }));
    }, []);

    const onVolumeChange = useCallback((trackKey: string, value: number) => {
        setMix(previous => ({
            ...previous,
            [trackKey]: {
                ...previous[trackKey],
                volume: value,
            }
        }));
    }, []);

    const player = useMixPlayer(context.current, mix, initialPosition);

    const onSave = useCallback(() => {
        Object.values(mix).filter(t => !!t.voice).forEach(track => {
            track.voice?.update({ drift: track.drift });
        });
    }, [mix]);

    return (
        <>
            <Box paddingY={4} justifyContent="space-between" display="flex">
                <Box>
                    <Typography variant="h5" color="textSecondary">{props.song.choirName}</Typography>
                    <Typography variant="h4" color="textPrimary">{props.song.name}</Typography>
                </Box>
                <Box>
                    <Button color="secondary" size="large" variant="contained" onClick={onSave}>Save</Button>
                </Box>
            </Box>
            <SectionSubMenu activeSection="audiomix" />
            <Box marginY={4}>
                <Controls player={player} initialPosition={initialPosition} duration={duration} onPositionChange={value => setInitialPosition(value)} />
            </Box>
            <Paper style={{ marginBottom: 10 }}>
                {Object.values(mix).map(track => (
                    <VoiceRow key={track.key} track={track} onDriftChange={onDriftChange} onVolumeChange={onVolumeChange} />
                ))}
            </Paper>
            <Box>
                <TrackLoader allVoices={props.voices} audioCtx={context.current} onTrackLoaded={onTrackLoaded} />
            </Box>
        </>
    );
}

export function AudioMixPage() {
    const params = useParams<SongParams>();
    const song = useSongData(params.song);

    const [allVoices, error] = usePromise(() => fetchVoiceListing(params.song), [params.song]);

    return (
        <Background variant="lightblueTop">
            <MainBar />
            <Container maxWidth="lg" style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                <AuthWall>
                    {song.result?.data && allVoices && <LoadedPage songId={params.song} song={song.result.data} voices={allVoices} />}
                </AuthWall>
            </Container>
        </Background >
    );
}