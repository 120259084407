import { Box, Container, Typography, CircularProgress } from '@material-ui/core';
import React, { useReducer, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { AuthWall, useLoggedInUser } from '../auth';
import { FeatureWall } from '../features';
import { Background, MainBar } from '../layout';
import { SongData, useSongData, VoiceData, Entity } from '../metadata';
import { PreparingStep, RecordingStep, PreviewingStep, UploadingStep, PreparingOutput, VoiceFinishedStep, UploadingOutput, RecordingOutput } from './fragments';
import firebase from 'firebase';
import { BaseMediaMode } from '../playback';

interface SingState {
    currentStep: "PREPARATION" | "RECORDING" | "PREVIEWING" | "UPLOADING" | "COMMITTING";
    recordingTimestamp: number;
    baseMediaLocalUrl?: string;
    baseMediaMode?: BaseMediaMode;
    userMediaStream?: MediaStream;
    recordedOutput?: RecordingOutput;
    storageUrl?: string;
}

interface SingAction {
    type: "PREPARATION_DONE" | "RECORDING_DONE" | "PREVIEW_ACCEPTED" | "PREVIEW_REJECTED" | "UPLOAD_FINISHED";
    data?: any;
}

function flowReducer(state: SingState, action: SingAction): SingState {
    switch (action.type) {
        case "PREPARATION_DONE":
            return {
                ...state,
                currentStep: "RECORDING",
                baseMediaLocalUrl: action.data.baseMediaLocalUrl,
                baseMediaMode: action.data.baseMediaMode,
                userMediaStream: action.data.userMediaStream,
            };
        case "RECORDING_DONE":
            return {
                ...state,
                currentStep: "PREVIEWING",
                recordedOutput: action.data,
            }
        case "PREVIEW_REJECTED":
            return {
                ...state,
                currentStep: "RECORDING",
                recordedOutput: undefined,
            }
        case "PREVIEW_ACCEPTED":
            return {
                ...state,
                currentStep: "UPLOADING",
            };
        case "UPLOAD_FINISHED":
            return {
            ...state,
            currentStep: "COMMITTING",
            storageUrl: action.data.storageUrl,
        };
        default:
            return { ...state };
    }
}

export function LoadedPage(props: { params: VoiceRecordingParams, song: SongData, user: firebase.User }) {
    const [state, dispatch] = useReducer(flowReducer, {
        currentStep: "PREPARATION",
        recordingTimestamp: Date.now(),
    });

    const afterPreparing = useCallback((data: PreparingOutput) => {
        dispatch({ data, type: "PREPARATION_DONE" });
    }, []);

    const afterRecording = useCallback((data: RecordingOutput) => {
        dispatch({ data, type: "RECORDING_DONE" });
    }, []);

    const afterAccept = useCallback(() => {
        dispatch({ type: "PREVIEW_ACCEPTED" });
    }, []);

    const afterReject = useCallback(() => {
        URL.revokeObjectURL(state.recordedOutput?.url as string);
        dispatch({ type: "PREVIEW_REJECTED" });
    }, []);

    const afterUpload = useCallback((data: UploadingOutput) => {
        dispatch({ data, type: "UPLOAD_FINISHED" });
    }, []);

    return (
        <Container maxWidth="sm">
            <Box paddingY={4} justifyContent="flex-start">
                <Typography variant="h5" color="textSecondary">{props.song.channelName}</Typography>
                <Typography variant="h4" color="textPrimary">{props.song.name}</Typography>
            </Box>
            <FeatureWall required={["PlayH264", "AudioContext", "MediaRecorder"]}>
                <AuthWall>
                    {state.currentStep == "PREPARATION" && <PreparingStep
                        songId={props.params.song}
                        partKey={props.params.part}
                        songData={props.song}
                        onDone={afterPreparing}
                    />}

                    {state.currentStep == "RECORDING" && <RecordingStep
                        baseMediaMode={state.baseMediaMode as BaseMediaMode}
                        baseMediaLocalUrl={state.baseMediaLocalUrl as string}
                        userMediaStream={state.userMediaStream as MediaStream}
                        onDone={afterRecording}
                    />}

                    {state.currentStep == "PREVIEWING" && <PreviewingStep
                        recorded={state.recordedOutput as RecordingOutput}
                        onAccept={afterAccept}
                        onReject={afterReject}
                    />}

                    {state.currentStep == "UPLOADING" && <UploadingStep
                        partKey={props.params.part}
                        songId={props.params.song}
                        recorded={state.recordedOutput as RecordingOutput}
                        timestamp={state.recordingTimestamp}
                        onDone={afterUpload}
                    />}

                    {state.currentStep == "COMMITTING" && <VoiceFinishedStep
                        recorded={state.recordedOutput as RecordingOutput}
                        timestamp={state.recordingTimestamp}
                        storageUrl={state.storageUrl as string}
                        partKey={props.params.part}
                        songId={props.params.song}
                        songData={props.song}
                    />}
                </AuthWall>
            </FeatureWall>
        </Container>
    );
}

interface VoiceRecordingParams {
    song: string;
    part: string;
}

export function VoiceRecordingPage() {
    const params = useParams<VoiceRecordingParams>();
    const song = useSongData(params.song);
    const user = useLoggedInUser();

    return (
        <Background variant="lightblueTop">
            <MainBar />
            <AuthWall>
                {song.result?.data && user && <LoadedPage params={params} song={song.result.data} user={user} />}
            </AuthWall>
        </Background>
    );
}