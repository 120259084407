import React from 'react';
import { Button, Grid, Paper, Avatar, Box, Typography, Link } from '@material-ui/core';
import { DoubleContentBrick, ImageBlock, Section, TextBlock, TextBlockProps, TripleContentBrick, SingleContentBrick, FooterSection, MainBar } from '../layout';

import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import GithubIcon from '@material-ui/icons/GitHub';


interface EntityCardProps extends TextBlockProps {
    name: string;
    subtitle: string;
    imageSrc?: string;
    linkedIn?: string;
    twitter?: string;
    instagram?: string;
    github?: string;
}

function EntityCard(props: EntityCardProps) {
    return (
        <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box display="flex" flexDirection="column" alignItems="center" marginY={5}>
                <Avatar style={{ height: 150, width: 150 }} src={props.imageSrc} />
                <Typography variant="h5" style={{ textAlign: "center" }}>{props.name}</Typography>
                <Typography variant="h6" color="textSecondary">{props.subtitle}</Typography>
                <Box>
                    {props.linkedIn && <Link href={props.linkedIn} target="_blank" color="textSecondary"><LinkedInIcon fontSize="small" /></Link>}
                    {props.twitter && <Link href={props.twitter} target="_blank" color="textSecondary"><TwitterIcon fontSize="small" /></Link>}
                    {props.instagram && <Link href={props.instagram} target="_blank" color="textSecondary"><InstagramIcon fontSize="small" /></Link>}
                    {props.github && <Link href={props.github} target="_blank" color="textSecondary"><GithubIcon fontSize="small" /></Link>}
                </Box>
            </Box>
        </Grid>
    );
}

export function AboutPage() {
    return (
        <Section variant="lightblueTop">
            <MainBar />
            <DoubleContentBrick
                marginBottom={5}
                first={
                    <TextBlock
                        title="About Us"
                        subtitle="OpenChoir is developed by a group of people who believe that music will help us get through these moments of social distancing."
                    />
                }
                second={
                    <ImageBlock
                        imgStyles={{ maxWidth: "100%", maxHeight: 450 }}
                        source="illus6.png"
                    />
                }
            />
            <Section
                marginY={10}
                header={<Typography color="primary" gutterBottom variant="h4" style={{ textAlign: "center" }}>People with the Idea</Typography>}
                children={
                    <SingleContentBrick>
                        <Grid container justify="center">
                            <EntityCard
                                name="Clara Luther"
                                subtitle="Music"
                                imageSrc="clara.jpg"
                                linkedIn="https://www.linkedin.com/in/clara-luther-836b321a2"
                            />
                            <EntityCard
                                name="Santiago Carmuega"
                                subtitle="Software"
                                imageSrc="santicarmuega.jpg"
                                linkedIn="https://www.linkedin.com/in/santiagocarmuega/"
                                twitter="https://twitter.com/santicarmuega"
                                github="https://github.com/scarmuega"
                            />
                        </Grid>
                    </SingleContentBrick>
                }
            />
            <Section
                marginY={10}
                header={<Typography color="primary" gutterBottom variant="h4" style={{ textAlign: "center" }}>People Donating Time</Typography>}
                children={
                    <SingleContentBrick>
                        <Grid container justify="center">
                            <EntityCard
                                name="Federico Weill"
                                subtitle="Content"
                                imageSrc="fedeweill.jpg"
                                twitter="https://twitter.com/FedeWeill"
                            />
                            <EntityCard
                                name="Julieta Carmuega"
                                subtitle="Design"
                                imageSrc="julicarmuega.jpg"
                                linkedIn="https://www.linkedin.com/in/julieta-carmuega-uxmc-a1b4a8118"
                            />
                            <EntityCard
                                name="Fancisca Civit"
                                subtitle="Social Media"
                                imageSrc="fran.png"
                            />
                        </Grid>
                    </SingleContentBrick>
                }
            />
            <Section
                marginY={10}
                header={<Typography color="primary" gutterBottom variant="h4" style={{ textAlign: "center" }}>Organizations Donating Time</Typography>}
                children={
                    <SingleContentBrick>
                        <Grid container justify="center">
                            <EntityCard name="Bede's Grammar School" subtitle="Organization" imageSrc="bedes.jpg" />
                            <EntityCard name="Alberto Noble" subtitle="Bede's Headmaster" imageSrc="bertie.jpg" />
                            <EntityCard name="Eileen Noble" subtitle="Bede's Deputy Headmistress" imageSrc="eileen.jpg" />
                            <EntityCard name="Maria Laura Provvisionato" subtitle="Bede's Head Secondary English" imageSrc="malaura.png" />
                            <EntityCard name="Silvia Pedrini" subtitle="Bede's Head Secondary Spanish" imageSrc="silvia.png" />
                        </Grid>
                    </SingleContentBrick>
                }
            />
            <FooterSection />
        </Section>
    );
}