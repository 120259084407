import { Box, Card, CardHeader, CardMedia, Grid, Typography, CardContent, Avatar, Button, Link } from '@material-ui/core';
import firebase from "firebase";
import React, { useState } from 'react';
import { FooterSection, MainBar, Section, SingleContentBrick, TextBlockProps } from '../layout';
import { Entity, SongData, mapToEntity } from '../metadata';
import { useInterval, usePromise } from '../utils';
import choirs from './choirs.json';

const DEADLINE = 1601676300000;
//const DEADLINE = Date.now() + 10 * 1000;
const BROADCAST_URL = "https://youtu.be/H-iLRjoMaek";

function CountdownNumber(props: { value?: number, caption: string }) {
    return (
        <Box marginX={1} bgcolor="#f50057" color="#ffffff" flexShrink={0} width={80} display="flex" alignItems="center" flexDirection="column" borderRadius={5}>
            <Typography variant="h3">{props.value}</Typography>
            <Typography variant="caption">{props.caption}</Typography>
        </Box>
    );
}

const MINUTE_IN_MS = (1000 * 60);
const HOUR_IN_MS = MINUTE_IN_MS * 60;
const DAY_IN_MS = HOUR_IN_MS * 24;

type CountdownState = [number, number, number, number];

function Countdown(props: { diff: number | undefined }) {
    if (!props.diff) return null;

    if (props.diff <= 0) {
        return <Button color="secondary" variant="contained" size="large" style={{ fontSize: 24 }} href={BROADCAST_URL} target="_blank">Play</Button>
    }

    const days = Math.floor(props.diff / DAY_IN_MS);
    const daysReminder = props.diff % DAY_IN_MS;
    const hours = Math.floor(daysReminder / HOUR_IN_MS);
    const hoursReminder = daysReminder % HOUR_IN_MS;
    const minutes = Math.floor(hoursReminder / MINUTE_IN_MS);
    const minutesReminder = hoursReminder % MINUTE_IN_MS;
    const seconds = Math.floor(minutesReminder / 1000);

    return (
        <>
            <CountdownNumber value={days} caption="days" />
            <CountdownNumber value={hours} caption="hours" />
            <CountdownNumber value={minutes} caption="minutes" />
            <CountdownNumber value={seconds} caption="seconds" />
        </>
    );
}

function YouTubeEmbed(props: { videoUrl: string }) {
    const fixedEmbedUrl = props.videoUrl.replace("https://youtu.be/", "https://www.youtube.com/embed/");
    return (
        <iframe width="100%" height="315" src={fixedEmbedUrl} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    );
}

interface ChoirCardProps extends TextBlockProps {
    title: string;
    subtitle: string;
    imageSrc?: string | null;
}

function ChoirCard(props: ChoirCardProps) {
    return (
        <Box display="flex" flexDirection="column" alignItems="center" marginY={5}>
            {!!props.imageSrc && <img style={{ height: 100, width: 100, objectFit: "contain" }} src={`concert2020/${props.imageSrc}`} />}
            {!props.imageSrc && <Avatar style={{ height: 100, width: 100 }} />}
            <Typography variant="subtitle1" style={{ textAlign: "center" }}>{props.title}</Typography>
            <Typography variant="subtitle2" color="textSecondary">{props.subtitle}</Typography>
        </Box>
    );
}

export async function fetchSongListing(): Promise<Entity<SongData>[]> {
    const raw = await firebase.firestore()
        .collection("songs")
        .where("adhoc.concert2020", "==", true)
        .get();

    return raw.docs.map(raw => mapToEntity<SongData>(raw));
}


export function InterhousePage() {
    const [songs, error] = usePromise(() => fetchSongListing(), []);
    const [diff, setDiff] = useState<number>();

    useInterval(() => {
        const diff = DEADLINE - Date.now();
        setDiff(diff);
    }, 200);

    return (
        <Section variant="lightblueTop">
            <MainBar />
            <SingleContentBrick display="flex" alignItems="center" containerMaxWidth="md" marginY={3}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="h3" style={{ color: "#fafafa" }} align="center">Bede's Grammar School</Typography>
                    <Typography variant="h2" style={{ color: "#ffffff" }} align="center">Choir Interhouse</Typography>
                    <Typography variant="h5" color="textSecondary" align="center">Join us on October 2 for an online event to witness a choir competition between houses where students perform and conduct their own songs.</Typography>
                </Box>
            </SingleContentBrick>
            <SingleContentBrick display="flex" alignItems="center" containerMaxWidth="md">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Box height="30vw" width="50vw" display="flex" alignItems="center" justifyContent="center" style={{ backgroundImage: "url(gridbg.webp)", backgroundSize: "cover" }}>
                        <Countdown diff={diff} />
                    </Box>
                </Box>
            </SingleContentBrick>

            <Section
                marginY={10}
                header={<Typography color="primary" gutterBottom variant="h4" style={{ textAlign: "center" }}>When & Where</Typography>}
                children={<SingleContentBrick display="flex" alignItems="center" containerMaxWidth="md">
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography align="center" variant="h6" color="textSecondary">
                            Concert will be presented at <strong>19 hs</strong> (argentina) via <strong>YouTube</strong> live stream<br />
                            at the following link: <Link color="secondary" target="_blank" href={BROADCAST_URL}>{BROADCAST_URL}</Link>
                    </Typography>
                    </Box>
                </SingleContentBrick>}
            />

            <FooterSection />
        </Section>
    );
}