import { Box, Container, Grid, Typography, TextField, Button } from '@material-ui/core';
import React, { useState, useCallback } from 'react';
import { useParams } from "react-router-dom";
import { AuthWall } from '../auth';
import { VoiceSnapshot, VoiceRawVideo } from '../buffering';
import { Background, MainBar } from '../layout';
import { Entity, SongData, useEntity, useSongData, VoiceData, CropData } from "../metadata";
import { SectionSubMenu } from './common';

interface VoiceParams {
    song: string;
    user: string;
    attempt: string;
}

function LoadedPage(props: { song: Entity<SongData>, voice: Entity<VoiceData> }) {
    const [crop, setCrop] = useState<CropData>(props.voice.data?.crop || [0, 0, 0, 0]);

    const onSave = useCallback(() => {
        props.voice.update({ crop });
    }, [crop]);

    const onCropChange = useCallback((value: string, index: number) => {
        setCrop(previous => {
            const updated = [...previous] as CropData;
            updated[index] = parseInt(value, 10);
            return updated;
        });
    }, []);

    return (
        <>
            <Box paddingY={4} justifyContent="flex-start">
                <Typography variant="h5" color="textSecondary">{props.song.data?.channelName}</Typography>
                <Typography variant="h4" color="textPrimary">{props.song.data?.name}</Typography>
            </Box>
            <SectionSubMenu activeSection="recordings" />
            <Box paddingY={4} display="flex" justifySelf="stretch">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <VoiceRawVideo voice={props.voice?.data || null} style={{ width: "100%", height: "60vh", backgroundColor: "#000000" }} />
                    </Grid>
                    <Grid item xs={6}>
                        <VoiceSnapshot voice={props.voice.data} style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" flexDirection="column" alignItems="stretch">
                            <TextField label="top" value={crop[0]} onChange={evt => onCropChange(evt.target.value, 0)} />
                            <TextField label="right" value={crop[1]} onChange={evt => onCropChange(evt.target.value, 1)} />
                            <TextField label="bottom" value={crop[2]} onChange={evt => onCropChange(evt.target.value, 2)} />
                            <TextField label="left" value={crop[3]} onChange={evt => onCropChange(evt.target.value, 3)} />
                            <Button color="primary" onClick={onSave} variant="contained">Save</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box paddingY={4}>
                <Typography>solo status: {props.voice.data?.soloPhase || "none"}</Typography>
                <Button color="primary" variant="contained" onClick={() => props.voice.update({ soloPhase: "requested" })}>Request Solo</Button>
            </Box>
        </>
    );
}

export function VoiceMixPage() {
    const params = useParams<VoiceParams>();
    const song = useSongData(params.song);
    const voice = useEntity<VoiceData>("voices", `${params.song}-${params.user}-${params.attempt}`);

    return (
        <Background variant="lightblueTop">
            <MainBar />
            <Container maxWidth="lg" style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                <AuthWall>
                    {song.result?.data && voice.result?.data && <LoadedPage song={song.result} voice={voice.result} />}
                </AuthWall>
            </Container>
        </Background >
    );
}