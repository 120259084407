import { Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, CircularProgress, LinearProgress, Step, StepContent, StepLabel, Stepper, Typography } from '@material-ui/core';
import MicIcon from '@material-ui/icons/Mic';
import * as firebase from 'firebase/app';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useCallForward } from 'react-callforward';
import { useLoggedInUser } from '../auth';
import { useFirebaseDownloader } from '../buffering';
import { setNewVoiceData, SongData } from '../metadata';
import { AnyBaseMediaView, BaseMediaMode, inferPartBaseMedia } from '../playback';
import { useFirebaseUploader } from '../uploading';
import { usePromise, useInterval } from '../utils';
import { LivePreview, MicLevel, useRecorder, useUserMedia } from './framework';

export interface PreparingOutput {
    baseMediaLocalUrl: string;
    baseMediaMode: BaseMediaMode;
    userMediaStream: MediaStream;
}

interface PreparingStepProps {
    songId: string;
    partKey: string;
    songData: SongData;
    onDone: (output: PreparingOutput) => void;
}

export function PreparingStep(props: PreparingStepProps) {
    const [subStep, setSubStep] = useState<number>(0);

    const contraints = useMemo(() => ({ audio: true, video: { facingMode: "user" } }), []);
    const [userMediaStream, mediaError] = useUserMedia(contraints);

    const [baseMedia, error] = usePromise(async () => {
        return await inferPartBaseMedia(props.songId, props.songData, props.partKey);
    }, [props.songId, props.songData, props.partKey]);

    if (error) {
        console.log(error);
    }

    const download = useFirebaseDownloader(baseMedia?.path || null, "blob");

    const onContinue = useCallback(() => {
        if (!baseMedia || !download.objectUrl || !userMediaStream) return;

        props.onDone({
            baseMediaLocalUrl: download.objectUrl,
            baseMediaMode: baseMedia.mode,
            userMediaStream
        });
    }, [download.objectUrl, userMediaStream]);

    return (
        <Card>
            <CardHeader title="Prepare" subheader="Make sure we are ready to record" />
            <CardMedia>
                <Stepper activeStep={subStep} orientation="vertical">
                    <Step>
                        <StepLabel>Make sure you have your headphones connected</StepLabel>
                        <StepContent>
                            <Button variant="contained" color="primary" onClick={() => setSubStep(1)}>They are on!</Button>
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>Make sure your mic is on</StepLabel>
                        <StepContent>
                            <Box display="flex" flexDirection="column" alignItems="stretch">
                                <Typography variant="body2">make a sound and confirm that the sound wave moves:</Typography>
                                <Box display="flex">
                                    <MicLevel stream={userMediaStream} height="100" />
                                </Box>
                                <Button variant="contained" color="primary" onClick={() => setSubStep(2)}>It's working!</Button>
                            </Box>
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>Make sure your camera is on</StepLabel>
                        <StepContent>
                            <Typography variant="body2">you should see yourself in the video below:</Typography>
                            <LivePreview stream={userMediaStream} width="100%" />
                            <Button variant="contained" color="primary" onClick={() => setSubStep(3)}>It's working!</Button>
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>Wait until we finish downloading the director's track</StepLabel>
                        <StepContent>
                            <Box paddingY={1}>
                                <LinearProgress variant="determinate" value={download.progress ? download.progress * 100 : 0} />
                            </Box>
                            <Button color="primary" variant="contained" disabled={!download.objectUrl} onClick={() => setSubStep(4)}>Done</Button>
                        </StepContent>
                    </Step>
                </Stepper>
            </CardMedia>
            <CardActions>
                <Button color="secondary" variant="contained" disabled={subStep != 4} onClick={onContinue}>Continue</Button>
            </CardActions>
        </Card>
    )
}

export interface RecordingOutput {
    blob: Blob,
    url: string;
    inpoint: number;
}

interface RecordingStepProps {
    baseMediaLocalUrl: string;
    baseMediaMode: BaseMediaMode;
    userMediaStream: MediaStream;
    onDone: (output: RecordingOutput) => void;
}

function computeRecordingInpoint(recordingStart: number, playbackPosition: number, previousInpoint?: number): number {
    const recordingPosition = Date.now() - recordingStart;
    const inpoint = recordingPosition - playbackPosition;
    return !!previousInpoint ? ((previousInpoint * 5) + inpoint) / 6 : inpoint;
}

export function RecordingStep(props: RecordingStepProps) {
    const recording = useRecorder(props.userMediaStream);

    const [duration, setDuration] = useState<number>();
    const [progress, setProgress] = useState<number>(0);
    const [inpoint, setInpoint] = useState<number>();
    
    const [onPlay, doPlay] = useCallForward([]);
    const [onPause, doPause] = useCallForward([]);

    const onRecord = useCallback(() => {
        if (!recording) throw new Error("recording not ready");
        recording.startRecording();
        setTimeout(() => onPlay(), 2000);
    }, [recording]);
 
    const onProgress = useCallback((position: number) => {
        setInpoint(previous => computeRecordingInpoint(recording.startTs as number, position * 1000, previous));
        const progress = duration && (position / duration) * 100 || 0
        setProgress(progress);
    }, [recording, duration]);

    const onEnd = useCallback(() => {
        if (!recording) throw new Error("recording not ready");
        recording.stopRecording();
        onPause();
    }, [recording]);

    useEffect(() => {
        if (!recording.output) return;
        props.onDone({
            blob: recording.output.blob,
            url: recording.output.url,
            inpoint: inpoint as number
        });
    }, [recording.output]);

    return (
        <Card>
            <CardMedia>
                <AnyBaseMediaView
                    baseMediaMode={props.baseMediaMode}
                    baseMediaUrl={props.baseMediaLocalUrl}
                    onCanPlay={setDuration}
                    onProgress={onProgress}
                    doPlay={doPlay}
                    doPause={doPause}
                    onEnded={onEnd}
                />
                <Box paddingY={1}>
                    <LinearProgress variant="determinate" value={progress} style={{ height: 10 }} />
                </Box>
            </CardMedia>
            <CardContent>
                <Box display="flex">
                    <LivePreview height={80} stream={props.userMediaStream} />
                    <MicLevel height={80} stream={props.userMediaStream} />
                </Box>
            </CardContent>
            <CardActions>
                <Button
                    color="secondary"
                    disableElevation
                    startIcon={<MicIcon fontSize="large" />}
                    variant="contained"
                    onClick={onRecord}
                    disabled={recording.isRecording}>
                    {recording.isRecording ? "Recording..." : "Record"}
                </Button>
                {recording.isRecording && <Button color="primary" disableElevation variant="contained" onClick={onEnd}>Cancel</Button>}
            </CardActions>
        </Card>
    )
}

export interface PreviewingStepProps {
    recorded: RecordingOutput;
    onAccept: () => void;
    onReject: () => void;
}

export function PreviewingStep(props: PreviewingStepProps) {
    return (
        <Card>
            <CardHeader title="Preview" subheader="preview your recording before uploading" />
            <CardMedia>
                <video style={{ width: "100%" }} controls>
                    <source src={props.recorded.url} />
                </video>
            </CardMedia>
            <CardActions>
                <Button color="secondary" variant="contained" disableElevation onClick={props.onAccept}>Send</Button>
                <Button variant="contained" disableElevation onClick={props.onReject}>Try Again</Button>
            </CardActions>
        </Card>
    )
}

export interface UploadingOutput {
    storageUrl: string;
}

export interface UploadingStepProps {
    songId: string;
    partKey: string;
    recorded: RecordingOutput;
    timestamp: number;
    onDone: (output: UploadingOutput) => void;
}

export function UploadingStep(props: UploadingStepProps) {
    const user = useLoggedInUser();

    const ref = useMemo(() => {
        const path = `${props.songId}/voice-${user?.uid}-${props.timestamp}.raw`
        return firebase.storage().ref(path);
    }, [user, props.songId]);

    const customMetadata = useMemo(() => ({
        userDisplayName: user?.displayName || "unknwon",
        userEmail: user?.email || "unknwon",
        userUid: user?.uid || "unknwon",
        songId: props.songId,
        partKey: props.partKey,
    }), [user, props.songId, props.partKey]);

    const uploader = useFirebaseUploader(props.recorded.blob, ref, props.recorded.blob?.type, customMetadata);

    useEffect(() => {
        if (uploader.isComplete) props.onDone({ storageUrl: ref.toString() });
    }, [uploader.isComplete])

    return (
        <Card>
            <CardHeader title="Uploading" subheader="your voice is being uploaded, please wait" />
            <CardContent>
                {uploader.isRunning && <LinearProgress variant="determinate" value={uploader.progress ? uploader.progress * 100 : undefined} />}
                {uploader.errorCode && <Typography><p>Something failed while uploading your video. Please <a href={props.recorded.url} download>download it</a> and send it via email to <a href="mailto:support@openchoir.com">support@openchoir.com</a>.</p></Typography>}
                <Typography variant="caption" color="textSecondary">If you want, you can also <a href={props.recorded.url} download="myrecording.mkv">download your video</a></Typography>
            </CardContent>
        </Card>
    )
}



export interface VoiceFinishedStepProps {
    recorded: RecordingOutput;
    timestamp: number;
    songId: string;
    songData: SongData;
    partKey: string;
    storageUrl: string;
}

export function VoiceFinishedStep(props: VoiceFinishedStepProps) {
    const [working, setWorking] = useState<boolean>();
    const [error, setError] = useState<Error>();
    const user = useLoggedInUser();

    useEffect(() => {
        if (!user) return;
        setWorking(true);
        setNewVoiceData(user, props.songId, props.songData, props.partKey, props.timestamp, props.storageUrl, props.recorded.inpoint)
            .then(() => {
                setWorking(false);
            })
            .catch(err => {
                setWorking(false);
                console.log(err);
                setError(err);
            });
    }, [user]);

    return (
        <Card>
            {working && <CardHeader title="Finishing" subheader="almost done!" />}
            {!working && <CardHeader title="Finished" subheader="you're all done!" />}
            <CardContent>
                {working && <Box alignItems="center" justifyContent="center" display="flex" flexGrow={1}>
                    <CircularProgress />
                </Box>}
                {!working && !error && <>
                    <Typography variant="subtitle1">Your voice has been uploaded. Now, we need to add it to the mix. You will be get an email when it's ready</Typography>
                    <Typography variant="caption" color="textSecondary">If you want, you can also <a href={props.recorded.url} download="myrecording.mkv">download your video</a></Typography>
                </>}
                {!!error && <>
                    <Typography variant="subtitle1">Ups, something happened while finishing up your voice upload. Please <a href={props.recorded.url} download="myrecording.mkv">download your video</a> and email it to <a href="mailto:support@openchoir.com">support@openchoir.com</a>.</Typography>
                </>}
            </CardContent>
        </Card>
    )
}


