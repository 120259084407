import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, LinearProgress, MenuItem, Paper, Select, Step, StepLabel, Stepper, TextField, Typography } from '@material-ui/core';
import firebase from 'firebase';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AuthWall, useLoggedInUser } from '../auth';
import { FooterSection, MainBar, Section, SingleContentBrick, TextBlock } from '../layout';
import { SongData, useEntity, Loadable, Entity, ChoirData } from '../metadata';
import { slugify } from './slugs';
import { useFirebaseUploader } from '../uploading';
import { useFormik } from 'formik';
import * as Yup from 'yup';

interface SetProps {
    state: ConcertSignupState;
    setState: SetConcertSignupState;
}

interface InfoFormValues {
    choirName: string;
    choirType: string;
    conductorName: string;
    country: string;
}

const InfoFormSchema = Yup.object({
    choirName: Yup.string().required(),
    choirType: Yup.string().required(),
    conductorName: Yup.string().required(),
    country: Yup.string().required(),
});

function InfoStep(props: SetProps) {
    const form = useFormik<InfoFormValues>({
        initialValues: { choirName: "", choirType: "", conductorName: "", country: "" },
        validationSchema: InfoFormSchema,
        onSubmit: () => { }
    });

    const onContinue = useCallback(() => {
        const choirId = slugify(form.values.choirName);
        props.setState(val => ({ ...val, ...form.values, choirId, step: 1 }))
    }, [form.values]);

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
                <TextField
                    name="choirName"
                    value={form.values.choirName}
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                    error={!!form.touched.choirName && !!form.errors.choirName}
                    helperText={!!form.touched.choirName && form.errors.choirName}
                    label="Choir Name"
                    fullWidth
                    variant="filled"
                />
            </Grid>
            <Grid item xs={12} md={8}>
                <TextField
                    select
                    name="choirType"
                    value={form.values.choirType}
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                    error={!!form.touched.choirType && !!form.errors.choirType}
                    helperText={!!form.touched.choirType && form.errors.choirType}
                    label="Choir Type"
                    variant="filled"
                    fullWidth
                >
                    <MenuItem value="adults">Adults</MenuItem>
                    <MenuItem value="teenagers">Teenagers</MenuItem>
                    <MenuItem value="children">Children</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={12} md={8}>
                <TextField
                    name="conductorName"
                    value={form.values.conductorName}
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                    error={!!form.touched.conductorName && !!form.errors.conductorName}
                    helperText={!!form.touched.conductorName && form.errors.conductorName}
                    label="Conductor Name"
                    fullWidth
                    variant="filled"
                />
            </Grid>
            <Grid item xs={12} md={8}>
                <TextField
                    name="country"
                    value={form.values.country}
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                    error={!!form.touched.country && !!form.errors.country}
                    helperText={!!form.touched.country && form.errors.country}
                    label="Country"
                    fullWidth
                    variant="filled"
                />
            </Grid>
            <Grid item xs={12} md={8}>
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    disabled={!form.isValid || !form.dirty}
                    onClick={onContinue}>
                    Continue
                </Button>
            </Grid>
        </Grid>
    );
}

function SongsStep(props: SetProps) {
    const [sharedSong, setSharedSong] = useState<boolean>(props.state.sharedSong);
    const [ownSong, setOwnSong] = useState<boolean>(props.state.ownSong);
    const [songName, setSongName] = useState<string>(props.state.songName);

    const valid = useMemo(() => {
        return !ownSong || !!songName;
    }, [ownSong, songName]);

    const onContinue = useCallback(() => {
        const songId = slugify(songName);
        props.setState(val => ({
            ...val,
            sharedSong,
            ownSong,
            songId,
            songName,
            step: ownSong ? 2 : 4,
        }))
    }, [songName, sharedSong, ownSong]);

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
                <Typography variant="h6" color="textSecondary">For the concert, you can prepare your own song, join a shared song, or both. Please select the options matching your preferences.</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
                <FormControl>
                    <FormGroup>
                        <FormControlLabel
                            label={"My choir wishes to participate in the shared song"}
                            control={<Checkbox checked={sharedSong} onChange={(_, checked) => setSharedSong(checked)} />}
                        />
                        <FormControlLabel
                            label={"My choir wishes to sign our own song"}
                            control={<Checkbox checked={ownSong} onChange={(_, checked) => setOwnSong(checked)} />}
                        />
                        <TextField
                            value={songName}
                            disabled={!ownSong}
                            onChange={evt => setSongName(evt.target.value)}
                            label="Song Name"
                            fullWidth
                            variant="filled"
                        />
                    </FormGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={8}>
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    disabled={!valid}
                    onClick={onContinue}>
                    Continue
                </Button>
            </Grid>
        </Grid>
    );
}

function SoundtrackStep(props: SetProps) {
    const [file, setFile] = useState<File>();

    const songId = useMemo(() => slugify(props.state.songName), [props.state.songName])

    const ref = useMemo(() => {
        return firebase.storage().ref(`${songId}/soundtrack`);
    }, [songId]);

    const meta = useMemo(() => ({
        songId: songId,
        songName: props.state.songName,
        conductorName: props.state.conductorName,
        choirName: props.state.choirName,
    }), [props.state]);

    const uploader = useFirebaseUploader(file, ref, file?.type, meta);

    const onFileSelected = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            const file = e.target.files[0];
            setFile(file);
        }
    }, []);

    const onContinue = useCallback(() => {
        props.setState(val => ({
            ...val,
            songId,
            step: 3
        }));
    }, [songId]);

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
                <Typography variant="h6" color="textSecondary">It needs to be an audio file (mp3 or wav). It will be used to define the tempo of your song. You can choose to mute this track in the final mix.</Typography>
                <Button variant="contained" color="primary" component="label" disabled={!!file}>
                    Select Soundtrack File
                    <input type="file" style={{ display: "none" }} onChange={onFileSelected} />
                </Button>
            </Grid>
            {file && <Grid item xs={12} md={8}>
                <Box paddingY={1}>
                    <Typography variant="caption">Uploading {file.name}</Typography>
                    <LinearProgress variant="determinate" value={(uploader.progress || 0) * 100} style={{ height: 10 }} />
                </Box>
            </Grid>}
            <Grid item xs={12} md={8}>
                <Button variant="contained" color="secondary" size="large" disabled={!uploader.isComplete} onClick={onContinue}>Continue</Button>
            </Grid>
        </Grid>
    );
}

function PartsStep(props: SetProps) {
    const [parts, setParts] = useState<Record<string, boolean>>({
        soprano: false,
        mezzo: false,
        alto: false,
        tenor: false,
        baritone: false,
        bass: false,
        solo1: false,
        solo2: false,
        solo3: false,
    });

    const onCheckChange = useCallback((id: string, checked: boolean) => {
        setParts(parts => ({ ...parts, [id]: checked }));
    }, []);

    const valid = useMemo(() => {
        return Object.values(parts).some(checked => !!checked);
    }, [parts]);

    const onContinue = useCallback(() => {
        const selectedParts = Object.entries(parts).filter(entry => !!entry[1]).map(entry => entry[0]);
        props.setState(val => ({ ...val, parts: selectedParts, step: 4 }));
    }, [parts]);

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
                <Typography variant="h6" color="textSecondary">Select the differents parts of your song. Each of the singer will join by recording on of these parts.</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
                <FormControl>
                    <FormGroup>
                        {Object.keys(parts).map(key => (
                            <FormControlLabel
                                key={key}
                                label={key}
                                control={<Checkbox checked={parts[key]} onChange={(_, checked) => onCheckChange(key, checked)} name={key} />}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={8}>
                <Button variant="contained" color="secondary" size="large" disabled={!valid} onClick={onContinue}>Continue</Button>
            </Grid>
        </Grid>
    )
}

async function applySongEntity(state: ConcertSignupState, user: firebase.User, entity: Entity<SongData>) {
    const data: SongData = {
        name: state.songName,
        choirName: state.choirName,
        choirRef: firebase.firestore().collection("choirs").doc(state.choirId),
        channelName: state.choirName,
        createdOn: new Date().toISOString(),
        voiceCount: 0,
        createdBy: {
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
        },
        parts: state.parts.reduce((all, next) => ({ ...all, [next]: { directorMode: "" } }), {}),
    };

    await entity.set(data, true);
}

async function applyChoirEntity(state: ConcertSignupState, user: firebase.User, entity: Entity<ChoirData>) {
    const data: ChoirData = {
        name: state.choirName,
        type: state.choirType,
        conductorName: state.conductorName,
        createdOn: new Date().toISOString(),
        country: state.country,
        createdBy: {
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
        },
        adhoc: {
            concert2: { sharedSong: state.sharedSong },
            concertSongs: [state.songId],
        }
    };

    await entity.set(data, true);
}

async function applyChanges(state: ConcertSignupState, user: firebase.User, song: Entity<SongData>, choir: Entity<ChoirData>) {
    await applyChoirEntity(state, user, choir);

    if (state.ownSong) {
        await applySongEntity(state, user, song);
    }
}

function FinishStep(props: SetProps) {
    const song = useEntity<SongData>("songs", props.state.songId as string);
    const choir = useEntity<ChoirData>("choirs", props.state.choirId as string);
    const [done, setDone] = useState<boolean>(false);
    const user = useLoggedInUser() as firebase.User;

    useEffect(() => {
        if (!song.result || !choir.result) return;
        applyChanges(props.state, user, song.result, choir.result).then(() => setDone(true));
    }, [props.state, song.result, choir.result]);

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
                <Box margin={4} flexGrow={1} display="flex" alignItems="center" justifyContent="center">
                    {!done && <CircularProgress />}
                    {done && <Typography variant="h5">Your registrations has been submitted. You'll receive an email when we're ready to start recording.</Typography>}
                </Box>
            </Grid>
        </Grid>
    )
}

interface ConcertSignupState {
    step: number;
    ownSong: boolean;
    conductorName: string;
    choirName: string;
    choirType: string;
    country: string;
    songName: string;
    parts: string[];
    songId?: string;
    choirId?: string;
    sharedSong: boolean;
}

type SetConcertSignupState = React.Dispatch<React.SetStateAction<ConcertSignupState>>;

const EMPTY_SONG_STATE: ConcertSignupState = {
    step: 0,
    ownSong: false,
    sharedSong: false,
    choirName: "",
    choirType: "",
    songName: "",
    conductorName: "",
    country: "",
    parts: [],
};

export function ConcertSignupPage() {
    const [state, setState] = useState<ConcertSignupState>(EMPTY_SONG_STATE);

    return (
        <Section variant="lightblueTop">
            <MainBar homeHref="/concert" />
            <SingleContentBrick
                children={
                    <TextBlock
                        marginY={3}
                        title="Concert Signup"
                        subtitle="Let's sign up for the concert, we need some information"
                    />
                }
            />
            <SingleContentBrick paddingY={1}>
                <Paper elevation={2} style={{ flexGrow: 1 }}>
                    <Stepper activeStep={state.step}>
                        <Step>
                            <StepLabel>Info</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Songs</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Backing Track</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Parts</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Finish</StepLabel>
                        </Step>
                    </Stepper>
                    <Box padding={5}>
                        <AuthWall>
                            {state.step == 0 && <InfoStep state={state} setState={setState} />}
                            {state.step == 1 && <SongsStep state={state} setState={setState} />}
                            {state.step == 2 && <SoundtrackStep state={state} setState={setState} />}
                            {state.step == 3 && <PartsStep state={state} setState={setState} />}
                            {state.step == 4 && <FinishStep state={state} setState={setState} />}
                        </AuthWall>
                    </Box>
                </Paper>
            </SingleContentBrick>
            <FooterSection />
        </Section>
    );
}