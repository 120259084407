import { Button, Card, CardActions, CardContent, CardMedia, Typography, Dialog, DialogContent, Grid, Paper, Tabs, Tab, Menu, MenuItem, CircularProgress } from "@material-ui/core";
import { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { getVoiceAssetPathFromData, useFirebaseDownloadUrl, VoiceSnapshot } from "../buffering";
import { Entity, VoiceData } from "../metadata";
import { MediaPlaceHolder } from "../uikit";
import React from "react";
import { Link as LinkRouter } from "react-router-dom";

import DownloadIcon from '@material-ui/icons/CloudDownload';
import ArchiveIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/RestoreFromTrash';

function toVoiceTitle(data?: VoiceData | null): string {
    if (!data) return "no data";
    const createdOn = data.createdOn;
    return new Date(createdOn).toLocaleString();
}

export function DownloadButton(props: { voiceData: VoiceData }) {
    const [open, setOpen] = useState<boolean>(false);
    const anchor = useRef(null);

    const rawPath = getVoiceAssetPathFromData(props.voiceData, "raw");
    const audioPath = getVoiceAssetPathFromData(props.voiceData, "audio");
    const videoPath = getVoiceAssetPathFromData(props.voiceData, "video");
    const soloPath = getVoiceAssetPathFromData(props.voiceData, "solo");

    const [rawUrl, rawErr] = useFirebaseDownloadUrl(open ? rawPath : null);
    const [audioUrl, audioErr] = useFirebaseDownloadUrl(open ? audioPath : null);
    const [videoUrl, videoErr] = useFirebaseDownloadUrl(open ? videoPath : null);
    const [soloUrl, soloErr] = useFirebaseDownloadUrl(open ? soloPath : null);

    const loading = (rawUrl === null || audioUrl === null || videoUrl === null || (soloUrl === null && !soloErr));

    const icon = useMemo(() => {
        return open && loading ? <CircularProgress size={10} /> : <DownloadIcon fontSize="small" />;
    }, [open, loading]);

    const onDownload = (url: string) => {
        window.location.href = url;
        setOpen(false);
    };

    return (
        <>
            <Button ref={anchor} color="secondary" disabled={loading} startIcon={icon} onClick={() => setOpen(true)}>
                Download
            </Button>
            <Menu
                anchorEl={anchor.current}
                open={open && !loading}
                onClose={() => setOpen(false)}
            >
                <MenuItem disabled={!rawUrl} onClick={() => onDownload(rawUrl as string)}>Raw Recording</MenuItem>
                <MenuItem disabled={!audioUrl} onClick={() => onDownload(audioUrl as string)}>Audio Track</MenuItem>
                <MenuItem disabled={!videoUrl} onClick={() => onDownload(videoUrl as string)}>Video Track</MenuItem>
                <MenuItem disabled={!soloUrl} onClick={() => onDownload(soloUrl as string)}>Solo Merge</MenuItem>
            </Menu>
        </>
    );
}

export function VoiceCard(props: { voice: Entity<VoiceData>, onSelect: () => void, showActions?: boolean }) {
    const [archived, setArchived] = useState<boolean>();

    useEffect(() => {
        setArchived(props.voice.data?.archived);
    }, [props.voice.data?.phase]);

    const onArchive = useCallback(() => {
        props.voice.update({ archived: true }).then(() => setArchived(true));
    }, [props.voice]);

    const onRestore = useCallback(() => {
        props.voice.update({ archived: false }).then(() => setArchived(false));
    }, [props.voice]);

    const onReprocess = useCallback(() => {
        props.voice.update({ phase: "raw" }).then(() => setArchived(false));
    }, [props.voice]);

    const mediaStyle: React.CSSProperties = { width: "100%", height: 220, objectFit: "contain", backgroundColor: "#000000" };

    return (
        <>
            <Card>
                <CardMedia>
                    {archived && <MediaPlaceHolder style={mediaStyle} message="video has been archived" />}
                    {!archived && props.voice?.data?.phase == "raw" && <MediaPlaceHolder style={mediaStyle} loading={true} message="video is being processed" />}
                    {!archived && props.voice?.data?.phase == "distilled" && <VoiceSnapshot
                        voice={props.voice.data || null}
                        style={{ ...mediaStyle, cursor: "pointer" }}
                        onClick={props.onSelect}
                    />}
                </CardMedia>
                <CardContent>
                    <Typography variant="h6">
                        {toVoiceTitle(props.voice?.data)}
                    </Typography>
                    <Typography color="textSecondary">
                        part: {props.voice.data?.partKey}
                    </Typography>
                    <Typography color="textSecondary">
                        id: {props.voice.data?.attemptId}
                    </Typography>
                    <Typography color="textSecondary">
                        drift: {props.voice.data?.drift}
                    </Typography>
                    <Typography color="textSecondary">
                        authorized: {!!props.voice.data?.adhoc?.authorized ? "yes" : "no" }
                    </Typography>
                </CardContent>
                {props.showActions === true && <CardActions>
                    {props.voice.data && <DownloadButton voiceData={props.voice.data} /> }
                    {!archived && <Button startIcon={<ArchiveIcon fontSize="small" />} color="default" onClick={onArchive}>archive</Button>}
                    {archived && <Button startIcon={<RestoreIcon fontSize="small" />} color="secondary" onClick={onRestore}>restore</Button>}
                    {/* <Button startIcon={<RestoreIcon fontSize="small" />} color="secondary" onClick={onReprocess}>reprocess</Button> */}
                </CardActions>}
            </Card>
        </>
    );
}

interface VoiceSelectDialogProps {
    open: boolean;
    voices: Entity<VoiceData>[];
    onSelect: (voice: Entity<VoiceData>) => void;
}

export function VoiceSelectDialog(props: VoiceSelectDialogProps) {
    return <Dialog open={props.open} maxWidth="lg">
        <DialogContent >
            <Grid container spacing={3}>
                {props.voices.map(v => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <VoiceCard voice={v} onSelect={() => props.onSelect(v)} />
                    </Grid>
                ))}
            </Grid>
        </DialogContent>
    </Dialog>
}


export function SectionSubMenu(props: { activeSection: string }) {
    return (
        <Paper square>
            <Tabs
                value={props.activeSection}
                indicatorColor="secondary"
                textColor="secondary"
            >
                <Tab label="Recordings" value="recordings" component={LinkRouter} to="mix" />
                <Tab label="Face Grid" value="facegrid" component={LinkRouter} to="video" />
                <Tab label="Audio Mix" value="audiomix" component={LinkRouter} to="audio" />
                <Tab label="Video Output" value="videooutput" component={LinkRouter} to="output" />
            </Tabs>
        </Paper>
    )
}