import { Box, Button, Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useFirebaseDownloadUrl } from './buffering';
import { Background } from './layout';
import { SongData, useSongData } from './metadata';
import { SongPartPlayer } from './playback';

interface PracticeParams {
    song: string;
    part: string;
}

const useStyles = makeStyles(t => ({
    mixContainerPaper: {
        padding: t.spacing(1),
        display: "flex",
    },
    mixSummaryPaper: {
        padding: t.spacing(1),
        background: t.palette.grey[100],
        flexGrow: 1,

    }
}));

function LoadedPage(props: { songId: string, song: SongData, part: string }) {
    const styles = useStyles();

    const [lyricsUrl, lyricsError] = useFirebaseDownloadUrl(`${props.songId}/${props.part}-lyrics.pdf`);
    const [audioUrl, audioError] = useFirebaseDownloadUrl(`${props.songId}/${props.part}-guide.mp3`);

    return (
        <Background variant="lightblueTop">
            <Container maxWidth="lg" style={{ display: "flex", flexDirection: "column" }}>
                <Box paddingY={4} justifyContent="flex-start">
                    <Typography variant="h5" color="textSecondary">{props.song.channelName}</Typography>
                    <Typography variant="h4" color="textPrimary">{props.song.name} / {props.part}</Typography>
                </Box>
                <Paper className={styles.mixContainerPaper}>
                    <Grid container spacing={1}>
                        <Grid item sm={8} xs={12}>
                            <SongPartPlayer
                                songId={props.songId}
                                songData={props.song}
                                partKey={props.part}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <Box display="flex" flexDirection="column" alignItems="stretch" padding={2}>
                                {lyricsUrl && <Button fullWidth size="large" variant="contained" disableElevation color="primary" href={lyricsUrl}>Download Lyrics (pdf)</Button>}
                                {audioUrl && <Box marginY={2}>
                                    <Button fullWidth size="large" variant="contained" disableElevation color="primary" href={audioUrl}>Download Audio (mp3)</Button>
                                </Box>}
                                <Box marginY={2}>
                                    <Typography variant="body1">When you're ready practicing, record your track</Typography>
                                    <Button fullWidth component={Link} to={`/s/${props.songId}/v/${props.part}/sing`} size="large" disableElevation color="secondary" variant="contained">Record</Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Background>
    );
}


export function PracticePage() {
    const params = useParams<PracticeParams>();
    const song = useSongData(params.song);

    return (
        <>
            {song.result?.data && <LoadedPage songId={song.result.id} song={song.result.data} part={params.part} />}
        </>
    );
}