import { Box, Button, Container, Grid, Typography, Card, CardHeader, CardMedia, CardActions, Link } from '@material-ui/core';
import React from 'react';
import { useParams } from "react-router-dom";
import { AuthWall } from '../auth';
import { useFirebaseDownloadUrl, FirebaseVideo } from '../buffering';
import { Background, MainBar } from '../layout';
import { Entity, SongData, useSongData, useExportListing, ExportData } from "../metadata";
import { SectionSubMenu } from './common';
import { FeatureWall } from '../features';

interface SongParams {
    song: string;
}

function ExportCard(props: { export: Entity<ExportData> }) {
    const songId = props.export.data?.songRef?.id;
    const fileKey = props.export.data?.fileKey;
    const [exportUrl, exportErr] = useFirebaseDownloadUrl(`${songId}/export-${fileKey}`);

    return (
        <Card elevation={3}>
            <CardHeader title={props.export.data?.name} />
            <CardMedia>
                <FeatureWall required={['PlayH264']}>
                    <video style={{ width: "100%", outline: "none", height: 210, backgroundColor: "#000000" }} controls>
                        {!!exportUrl && <source src={exportUrl} />}
                    </video>
                </FeatureWall>
            </CardMedia>
            <CardActions>
                <Button href={exportUrl || undefined} disabled={!exportUrl} size="small" disableElevation color="secondary" variant="contained">Download</Button>
            </CardActions>
        </Card>
    )
}

function LoadedPage(props: { songId: string, song: Entity<SongData>, exports: Entity<ExportData>[] }) {
    return (
        <>
            <Box paddingY={4} justifyContent="flex-start">
                <Typography variant="h5" color="textSecondary">{props.song.data?.channelName}</Typography>
                <Typography variant="h4" color="textPrimary">{props.song.data?.name}</Typography>
            </Box>
            <SectionSubMenu activeSection="videooutput" />
            <Box paddingY={4} display="flex" justifySelf="stretch">
                {!!props.exports.length && <Grid container spacing={3}>
                    {props.exports.map(e =>
                        <Grid item xs={12} sm={6} md={4}>
                            <ExportCard export={e} />
                        </Grid>)}
                </Grid>}
                {!props.exports.length && <Typography>No video output has been generated yet, please come back later.</Typography>}
            </Box>
        </>
    );
}

export function OutputMixPage() {
    const params = useParams<SongParams>();
    const song = useSongData(params.song);
    const exports_ = useExportListing(params.song);

    return (
        <Background variant="lightblueTop">
            <MainBar />
            <Container maxWidth="lg" style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                <AuthWall>
                    {song.result?.data && exports_.result && <LoadedPage songId={params.song} song={song.result} exports={exports_.result} />}
                </AuthWall>
            </Container>
        </Background >
    );
}