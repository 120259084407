import { Box, Button, Card, CardActions, CardHeader, CardMedia, Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FirebaseImage, FirebaseVideo } from '../buffering';
import { FeatureWall } from '../features';
import { Background, FooterSection, MainBar, SingleContentBrick } from '../layout';
import { SongData, useSongData } from '../metadata';
import { AuthWall } from '../auth';

interface SongParams {
    song: string;
}

const useStyles = makeStyles(t => ({
    mixContainerPaper: {
        //padding: t.spacing(1),
        overflow: "hidden",
    },
    mixSummaryPaper: {
        //padding: t.spacing(1),
        //background: t.palette.grey[100],
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    }
}));

function PartCard(props: { songId: string, part: string, song: SongData }) {
    return (
        <Card elevation={3}>
            <CardHeader title={props.part} />
            <CardMedia>
                <FeatureWall required={['PlayH264']}>
                    <FirebaseVideo path={`${props.songId}/${props.part}-director.mp4`} style={{ width: "100%", outline: "none", height: 210, backgroundColor: "#000000" }} />
                </FeatureWall>
            </CardMedia>
            <CardActions>
                <Button component={Link} to={`/s/${props.songId}/v/${props.part}/sing`} size="small" disableElevation color="secondary" variant="contained">Sing</Button>
                <Button component={Link} to={`/s/${props.songId}/v/${props.part}/practice`} size="small" disableElevation variant="contained">Practice</Button>
            </CardActions>
        </Card>
    )
}

function MixHeader(props: { songId: string, song: SongData }) {
    const styles = useStyles();

    return (
        <Paper className={styles.mixContainerPaper}>
            <Grid container>
                <Grid item sm={8} xs={12}>
                    <FirebaseImage path={`${props.songId}/cover.jpg`} style={{ objectFit: "cover", width: "100%", height: "400px", filter: 'blur(8px) opacity(35%) grayscale(50%)' }} />
                </Grid>
                <Grid item sm={4} xs={12} style={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                    <Paper elevation={0} className={styles.mixSummaryPaper}>
                        <Typography variant="h1">{props.song.voiceCount}</Typography>
                        <Typography variant="body1">voices have joined so far</Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
}

{/* <img src="/videoshot.png" style={{ opacity: .1, filter: 'grayscale(70%)', maxWidth: "100%" }} />
                        <Box flexGrow={1} display="flex" alignItems="center" justifyContent="center" position="absolute">
                            <Typography>No mix available at this moment</Typography>
                        </Box> */}

function RatioBox(props: PropsWithChildren<{}>) {
    const el = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState<number>();
    
    useEffect(() => {
        if (!el.current) return;
        const div = el.current;
        setHeight(div.clientWidth / 1.77);
        div.onresize = () => {
            setHeight(div.clientWidth / 1.77);
        };
    }, [el]);

    return <div ref={el} style={{ width: "100%", height: `${height}px`}}>{props.children}</div>
}

function LoadedPage(props: { songId: string, song: SongData, coverUrl?: string }) {
    return (
        <Box flexGrow={1} display="flex" flexDirection="column">
            <MainBar />
            <SingleContentBrick
                marginY={5}
                children={
                   <Box>
                       <Typography variant="h5" color="textSecondary">{props.song.channelName}</Typography>
                       <Typography variant="h3">{props.song.name}</Typography>
                   </Box>
                }
            />
            <Container maxWidth="lg">
                {false && <MixHeader songId={props.songId} song={props.song} />}
                <Box marginY={4}>
                    <Typography color="primary" gutterBottom variant="h4">Parts</Typography>
                </Box>
                <Grid container spacing={5}>
                    {Object.keys(props.song.parts).map(part => (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <PartCard songId={props.songId} song={props.song} part={part} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <FooterSection />
        </Box>
    );
}


export function SongPage() {
    const params = useParams<SongParams>();
    const song = useSongData(params.song);
    //const [coverUrl, coverError] = useFirebaseDownloadUrl(`${params.song}/cover.jpg`);

    return (
        <Background variant="lightblueTop">
            <AuthWall>
                {song.result?.data && <LoadedPage song={song.result.data} songId={song.result.id} />}
            </AuthWall>
        </Background>
    );
}