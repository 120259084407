import { useState, useEffect, useRef, useCallback } from "react";

export function usePromise<T>(executor: () => Promise<T>, deps: any[]): [T | null | undefined, Error | undefined | null] {
    const [result, setResult] = useState<T | null>();
    const [error, setError] = useState<Error | null>();

    useEffect(() => {
        setResult(null);
        
        executor()
            .then(res => {
                setResult(res);
                setError(null);
            })
            .catch(err => {
                setError(err);
                setResult(undefined);
            });
    }, deps);

    return [result, error];
}


export function useInterval(callback: Function, delay: number | null) {
    const callbackRef = useRef<Function>();

    // Remember the latest callback.
    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            callbackRef.current && callbackRef.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}



export function useAnimationLoop(frame: (time: number) => void) {
    const requestRef = useRef<number>();

    const animate = useCallback((time: number) => {
        frame(time);
        requestRef.current = requestAnimationFrame(animate);
    }, [frame]);

    useEffect(() => {
        requestRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(requestRef.current as number);
    }, [animate]);
}
