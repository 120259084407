import { Box, Card, CardHeader, CardMedia, Grid, Typography, CardContent, Avatar, Button, Link } from '@material-ui/core';
import firebase from "firebase";
import React, { useState } from 'react';
import { FooterSection, MainBar, Section, SingleContentBrick, TextBlockProps } from '../layout';
import { Entity, SongData, mapToEntity } from '../metadata';
import { useInterval, usePromise } from '../utils';
import choirs from './choirs.json';

function YouTubeEmbed(props: { videoUrl: string }) {
    const fixedEmbedUrl = props.videoUrl.replace("https://youtu.be/", "https://www.youtube.com/embed/");
    return (
        <iframe width="100%" height="315" src={fixedEmbedUrl} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    );
}

function SongCard(props: { song: Entity<SongData>, diff: number | undefined }) {
    const videoUrl = props.song.data?.adhoc?.youtube as string;
    
    return (
        <Card elevation={3}>
            <CardHeader title={props.song.data?.name} subheader={props.song.data?.choirName} />
            <CardMedia>
                { videoUrl && <YouTubeEmbed videoUrl={videoUrl} /> }
            </CardMedia>
            <CardContent>
                <Typography variant="subtitle2">conducted by: {props.song.data?.adhoc?.ConductedBy}</Typography>
            </CardContent>
        </Card>
    )
}

interface ChoirCardProps extends TextBlockProps {
    title: string;
    subtitle: string;
    imageSrc?: string | null;
}

function ChoirCard(props: ChoirCardProps) {
    return (
        <Box display="flex" flexDirection="column" alignItems="center" marginY={5}>
            {!!props.imageSrc && <img style={{ height: 100, width: 100, objectFit: "contain" }} src={`concert2020/${props.imageSrc}`} />}
            {!props.imageSrc && <Avatar style={{ height: 100, width: 100 }} />}
            <Typography variant="subtitle1" style={{ textAlign: "center" }}>{props.title}</Typography>
            <Typography variant="subtitle2" color="textSecondary">{props.subtitle}</Typography>
        </Box>
    );
}

export async function fetchSongListing(): Promise<Entity<SongData>[]> {
    const raw = await firebase.firestore()
        .collection("songs")
        .where("adhoc.concert2020", "==", true)
        .get();

    return raw.docs.map(raw => mapToEntity<SongData>(raw));
}


export function Edition1Page() {
    const [songs, error] = usePromise(() => fetchSongListing(), []);
    const [diff, setDiff] = useState<number>();

    return (
        <Section variant="lightblueTop">
            <MainBar />
            <SingleContentBrick display="flex" alignItems="center" containerMaxWidth="md" marginY={3}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="h3" style={{ color: "#ffffff" }} align="center">OpenChoir In Concert - 1st Edition</Typography>
                    <Typography variant="h5" color="textSecondary" align="center">On the 2nd of July 2020, more than a 1000 voice, from 46 choirs and 6 different countries, joined in an online event to showcase their songs in a one-of-a-kind choral concert.</Typography>
                </Box>
            </SingleContentBrick>
            <SingleContentBrick display="flex" alignItems="center" containerMaxWidth="md">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/WFclcXGm9ZA"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </Box>
            </SingleContentBrick>

            <Section
                marginY={8}
                header={<Typography color="primary" gutterBottom variant="h4" style={{ textAlign: "center" }}>Participating Choirs</Typography>}
                children={
                    <SingleContentBrick >
                        <Grid container spacing={3}>
                            {choirs.map((s, i) => <Grid item xs={6} sm={4} md={3} lg={2}> <ChoirCard key={i} title={s.title} subtitle={s.country} imageSrc={s.logo} /> </Grid>)}
                        </Grid>
                    </SingleContentBrick>
                }
            />

            <Section
                marginY={8}
                header={<Typography color="primary" gutterBottom variant="h4" style={{ textAlign: "center" }}>Featured Songs</Typography>}
                children={
                    <SingleContentBrick paddingY={3}>
                        <Grid container spacing={3}>
                            {songs && songs.map(s => <Grid item xs={12} sm={6} md={4} lg={4}> <SongCard key={s.id} song={s} diff={diff} /> </Grid>)}
                        </Grid>
                    </SingleContentBrick>
                }
            />

            <FooterSection />
        </Section>
    );
}