import { useState, useEffect } from "react";
import firebase from "firebase";
import { usePromise } from "./utils";

export interface SongPartData {
    directorMode?: string;
}

export interface UserData {
    uid: string;
    displayName: string | null;
    email: string | null;
}

export interface ChoirData {
    name: string;
    type: string;
    conductorName: string;
    createdOn: string;
    createdBy: UserData;
    country: string;
    adhoc?: Record<string, object>;
}

export interface VideoCellData {
    voiceRef?: firebase.firestore.DocumentReference;
}

export interface VideoRowData {
    cells: VideoCellData[];
}

export interface VideoSceneData {
    rows: VideoRowData[];
}

export interface VideoMixData {
    scenes: VideoSceneData[];
}

export interface SongData {
    name: string;
    choirRef?: firebase.firestore.DocumentReference;
    choirName: string;
    parts: Record<string, SongPartData | null>;
    createdOn: string;
    createdBy: UserData;
    voiceCount: number;
    videoMix?: VideoMixData;
    adhoc?: Record<string, any>;

    //deprecated
    channelRef?: firebase.firestore.DocumentReference;
    channelName: string;
}

export type CropData = [number, number, number, number];

export interface VoiceData {
    songRef?: firebase.firestore.DocumentReference;
    songName: string;
    choirRef?: firebase.firestore.DocumentReference;
    choirName: string;
    attemptId: string;
    partKey: string;
    createdOn: string;
    createdBy: UserData;
    storageUrl: string;
    phase: string;
    archived: boolean;
    drift: number;
    crop?: CropData;
    adhoc?: Record<string, any>;
    soloPhase?: string;
}

export interface GuideData {
    songRef?: firebase.firestore.DocumentReference;
    songName: string;
    choirRef?: firebase.firestore.DocumentReference;
    choirName: string;
    attemptId: string;
    partKey: string;
    createdOn: string;
    createdBy: UserData;
    storageUrl: string;
    phase: string;
    archived: boolean;
    adhoc?: Record<string, object>;
}

export interface ExportData {
    songRef?: firebase.firestore.DocumentReference;
    songName: string;
    choirRef?: firebase.firestore.DocumentReference;
    choirName: string;
    createdOn: string;
    name: string;
    fileKey: string;
    type: string;
    phase: string;
    archived: boolean;
    adhoc?: Record<string, any>;
}

export interface Loadable<T> {
    result?: T | null,
    loading: boolean;
    error: any;
}

export interface Entity<T> {
    id: string;
    exists: boolean;
    data: T | null;
    set: (data: T, merge?: boolean) => Promise<void>;
    update: (data: Partial<T>) => Promise<void>;
}

export function mapToEntity<TData>(snapshot: firebase.firestore.DocumentSnapshot): Entity<TData> {
    const data = snapshot.data();

    return {
        id: snapshot.id,
        exists: snapshot.exists || false,
        set: (data, merge) => snapshot.ref.set(data, { merge }),
        update: (data: Partial<TData>) => snapshot.ref.update(data),
        data: data ? (data as TData) : null,
    };
}

export function useEntity<TData>(collection: string, id: string): Loadable<Entity<TData>> {
    const ref = firebase.firestore().collection(collection).doc(id);
    const [snapshot, error] = usePromise(() => ref.get(), [id]);

    return {
        result: !!snapshot ? mapToEntity<TData>(snapshot) : undefined,
        loading: !snapshot && !error,
        error,
    }
}


export function useSongData(id: string): Loadable<Entity<SongData>> {
    return useEntity("songs", id);
}

export async function fetchVoiceListing(songId: string, phase?: string): Promise<Entity<VoiceData>[]> {
    const songRef = firebase.firestore().collection("songs").doc(songId);

    let query = firebase
        .firestore()
        .collection("voices")
        .where("songRef", "==", songRef);

    if (!!phase) {
        query = query.where("phase", "==", phase);
    }

    const results = await query.get();
    return results.docs.map(doc => mapToEntity<VoiceData>(doc));
}

export async function fetchSongListing(): Promise<Entity<SongData>[]> {
    const raw = await firebase.firestore().collection("songs").limit(30).get();
    return raw.docs.map(raw => mapToEntity<SongData>(raw));
}

export function useSongListing(): Loadable<Entity<SongData>[]> {
    const [result, error] = usePromise(() => fetchSongListing(), []);

    return {
        result,
        loading: !result && !error,
        error,
    }
}

export async function fetchExportListing(songId: string, phase?: string): Promise<Entity<ExportData>[]> {
    const songRef = firebase.firestore().collection("songs").doc(songId);

    let query = firebase
        .firestore()
        .collection("exports")
        .where("songRef", "==", songRef);

    if (!!phase) {
        query = query.where("phase", "==", phase);
    }

    const results = await query.get();
    return results.docs.map(doc => mapToEntity<ExportData>(doc));
}

export function useExportListing(songId: string, phase?: string): Loadable<Entity<ExportData>[]> {
    const [result, error] = usePromise(() => fetchExportListing(songId, phase), []);

    return {
        result,
        loading: !result && !error,
        error,
    }
}

export async function setNewVoiceData(user: firebase.User, songId: string, songData: SongData, partKey: string, timestamp: number, storageUrl: string, drift: number) {
    const voiceId = `${songId}-${user.uid}-${timestamp}`;
    const ref = firebase.firestore().collection("voices").doc(voiceId);

    const data: VoiceData = {
        createdBy: {
            displayName: user.displayName,
            email: user.email,
            uid: user.uid,
        },
        phase: "raw",
        archived: false,
        createdOn: new Date(timestamp).toISOString(),
        songName: songData.name,
        attemptId: timestamp.toString(),
        songRef: firebase.firestore().collection("songs").doc(songId),
        choirName: songData.choirName || "unknown",
        choirRef: songData.choirRef,
        partKey: partKey,
        storageUrl: storageUrl,
        drift: drift,
    };

    await ref.set(data);
}


export async function setNewGuideData(user: firebase.User, songId: string, songData: SongData, partKey: string, timestamp: number, storageUrl: string) {
    const guideId = `${songId}-${user.uid}-${timestamp}`;
    const ref = firebase.firestore().collection("guides").doc(guideId);

    const data: GuideData = {
        createdBy: {
            displayName: user.displayName,
            email: user.email,
            uid: user.uid,
        },
        phase: "raw",
        archived: false,
        createdOn: new Date(timestamp).toISOString(),
        attemptId: timestamp.toString(),
        songName: songData.name,
        songRef: firebase.firestore().collection("songs").doc(songId),
        choirName: songData.choirName,
        choirRef: songData.choirRef,
        partKey: partKey,
        storageUrl: storageUrl,
    };

    await ref.set(data);
}
