import { Box, Button, Container, MenuItem, TextField, Typography } from '@material-ui/core';
import firebase from 'firebase';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { AuthWall } from '../auth';
import { VoiceSnapshot } from '../buffering';
import { Background, MainBar } from '../layout';
import { Entity, fetchVoiceListing, SongData, useSongData, VideoCellData, VideoMixData, VideoRowData, VideoSceneData, VoiceData } from "../metadata";
import { usePromise } from '../utils';
import { VoiceSelectDialog, SectionSubMenu } from './common';

interface SongParams {
    song: string;
}

interface VideoGridCellProps {
    cell: VideoCellData;
    onSelect: () => void;
}

function VideoGridCell(props: VideoGridCellProps) {
    const [voice, error] = usePromise(async () => {
        if (!props.cell.voiceRef) return undefined;
        const result = await props.cell.voiceRef?.get();
        return result.data() as VoiceData;
    }, [props.cell.voiceRef]);

    return (
        <Box bgcolor="#555555" margin={1} flexGrow={0} width={108} height={72}>
            {!props.cell.voiceRef && <Button onClick={props.onSelect}>empty</Button>}
            {voice && <VoiceSnapshot onClick={props.onSelect} voice={voice} style={{ width: "100%", height: "100%", objectFit: "cover" }} />}
        </Box>
    )
}

interface VideoGridRowProps {
    index: number;
    row: VideoRowData
    onCellSelect: (row: number, col: number) => void;
}

function VideoGridRow(props: VideoGridRowProps) {
    return (
        <Box display="flex" flexDirection="row" flexGrow={1} justifyContent="stretch">
            {props.row.cells.map((cell, index) => <VideoGridCell key={index} cell={cell} onSelect={() => props.onCellSelect(props.index, index)} />)}
        </Box>
    );
}

interface VideoGridProps {
    scene: VideoSceneData;
    onCellSelect: (row: number, col: number) => void;
}

function VideoGrid(props: VideoGridProps) {
    return (
        <Box display="flex" flexDirection="column" flexGrow={1} bgcolor="#000000" justifyContent="stretch">
            {props.scene.rows.map((row, index) => <VideoGridRow key={index} index={index} row={row} onCellSelect={(r, c) => props.onCellSelect(r, c)} />)}
        </Box>
    );
}

function buildNewVideoScene(dims: [number, number], previous?: VideoSceneData): VideoSceneData {
    const [rowCount, colCount] = dims;
    const output: VideoSceneData = { rows: Array(rowCount) }

    for (let r = 0; r < rowCount; r++) {
        output.rows[r] = { cells: Array(colCount) };
        for (let c = 0; c < colCount; c++) {
            const defaultCell = previous && previous.rows[r] && previous.rows[r].cells[c] || {};
            output.rows[r].cells[c] = defaultCell;
        }
    }

    return output;
}

function cloneVideoRow(original: VideoRowData): VideoRowData {
    return {
        cells: original.cells.map(c => ({ ...c })),
    }
}

function cloneVideoScene(original: VideoSceneData): VideoSceneData {
    return {
        rows: original.rows.map(r => cloneVideoRow(r)),
    }
}

function cloneVideoMix(original: VideoMixData): VideoMixData {
    return {
        scenes: original.scenes.map(s => cloneVideoScene(s)),
    }
}

interface SceneSection {
    index: number;
    data: VideoSceneData;
    onDimReset: (scene: number, cols: number, rows: number) => void;
    onCellSelect: (scene: number, row: number, col: number) => void;
}

function SceneSection(props: SceneSection) {
    const [dims, setDims] = useState<[number, number]>([7, 6]);

    return (
        <>
            <Box display="flex" flexGrow={1} justifyContent="space-between">
                <Box marginX={1}>
                    <TextField select label="rows" value={dims[0]} onChange={evt => setDims(d => [parseInt(evt.target.value, 10), d[1]])}>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                    </TextField>
                    <TextField select label="cols" value={dims[1]} type="number" onChange={evt => setDims(d => [d[0], parseInt(evt.target.value, 10)])}>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                    </TextField>
                    <Button color="primary" variant="contained" onClick={() => props.onDimReset(props.index, dims[0], dims[1])}>Reset</Button>
                </Box>
            </Box>
            <Box paddingY={4} display="flex" justifySelf="stretch">
                <VideoGrid scene={props.data} onCellSelect={(r, c) => props.onCellSelect(props.index, r, c)} />
            </Box>
        </>
    );
}

function LoadedPage(props: { songId: string, song: Entity<SongData>, voices: Entity<VoiceData>[] }) {
    const initialMix: VideoMixData = props.song.data?.videoMix || { scenes: [buildNewVideoScene([7, 6])] };
    const [mix, setMix] = useState<VideoMixData>(initialMix);
    const [selection, setSelection] = useState<[number, number, number]>();

    const onSave = useCallback(() => {
        if (!mix) return;
        props.song.update({ videoMix: mix }).then(() => alert("saved"));
    }, [mix]);

    const onAddScene = useCallback(() => {
        setMix(previous => {
            const newScene = buildNewVideoScene([7, 6]);
            const newMix = { ...previous, scenes: [...previous.scenes, newScene] };
            return newMix;
        });
    }, []);

    const onDimReset = useCallback((scene: number, cols: number, rows: number) => {
        setMix(previous => {
            const newMix = { ...previous, scenes: [...previous.scenes] };
            const previousScene = previous.scenes[scene];
            newMix.scenes[scene] = buildNewVideoScene([cols, rows], previousScene);
            return newMix;
        });
    }, [])

    const onCellSelect = useCallback((scene: number, col: number, row: number) => {
        setSelection([scene, col, row]);
    }, []);

    const onSelectVoice = useCallback((voice: Entity<VoiceData>) => {
        if (!mix || !selection) return;
        const [sceneIdx, rowIdx, colIdx] = selection;
        const newMix = cloneVideoMix(mix);
        const scene = newMix.scenes[sceneIdx];
        const row = scene.rows[rowIdx];
        const cell = row.cells[colIdx];
        cell.voiceRef = firebase.firestore().collection("voices").doc(voice.id);
        setMix(newMix);
        setSelection(undefined);
    }, [selection, mix]);

    return (
        <>
            <VoiceSelectDialog open={!!selection} onSelect={onSelectVoice} voices={props.voices} />
            <Box paddingY={4} justifyContent="space-between" display="flex">
                <Box>
                    <Typography variant="h5" color="textSecondary">{props.song.data?.choirName}</Typography>
                    <Typography variant="h4" color="textPrimary">{props.song.data?.name}</Typography>
                </Box>
                <Box>
                    <Button color="secondary" size="large" variant="contained" onClick={onSave}>Save</Button>
                </Box>
            </Box>
            <SectionSubMenu activeSection="facegrid" />
            <Box paddingY={4} flexDirection="column" display="flex" justifySelf="stretch">
                {mix?.scenes.map((data, index) => <SceneSection key={index} index={index} data={data} onDimReset={onDimReset} onCellSelect={onCellSelect} />)}
            </Box>
            <Box display="flex" flexGrow={1} justifyContent="space-between">
                <Box marginX={1} justifySelf="flex-end">
                    <Button color="primary" variant="contained" onClick={onAddScene}>Add Scene</Button>
                </Box>
            </Box>
        </>
    );
}

export function VideoMixPage() {
    const params = useParams<SongParams>();
    const song = useSongData(params.song);

    const [voices, error] = usePromise(() => fetchVoiceListing(params.song), [params.song]);

    return (
        <Background variant="lightblueTop">
            <MainBar />
            <Container maxWidth="lg" style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                <AuthWall>
                    {song.result?.data && voices && <LoadedPage songId={params.song} song={song.result} voices={voices} />}
                </AuthWall>
            </Container>
        </Background >
    );
}