import React, { useCallback, useState } from 'react';
import { useSongListing, SongData } from "../metadata";
import { Card, CardContent, CardHeader, Grid, Container, Avatar, Typography, CardMedia, CardActions, Button, Box, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { Background, Section, DoubleContentBrick, TextBlock, ImageBlock, FooterSection } from '../layout';
import { AuthWall } from '../auth';

interface JoinDialogProps {
    song: SongData | null;
}

function JoinDialog(props: JoinDialogProps) {
    const handleClose = useCallback(() => {

    }, [props.song]);

    return (
        <Dialog open={!!props.song} onClose={handleClose}>
            <DialogTitle>Subscribe</DialogTitle>
            <AuthWall>
                <DialogContent>
                    <DialogContentText>subscribe to {props.song?.name}</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
          </Button>
                    <Button onClick={handleClose} color="primary">
                        Subscribe
          </Button>
                </DialogActions>
            </AuthWall>
        </Dialog>
    )
}


function SongCard(props: { song: SongData, onClick: () => void }) {
    return (
        <Card elevation={3}>
            <CardHeader avatar={<Avatar />} title={props.song.channelName} subheader={"date"} />
            <CardMedia>
                <img src="https://img.discogs.com/ogY20BUVI7IbfhkhbfCR4p1Srzc=/fit-in/300x300/filters:strip_icc():format(jpeg):mode_rgb():quality(40)/discogs-images/R-11856611-1523561371-9120.jpeg.jpg" />
            </CardMedia>
            <CardContent>
                <Typography variant="h6">{props.song.name}</Typography>
            </CardContent>
            <CardActions>
                <Button variant="contained" color="secondary" disableElevation onClick={props.onClick}>Ask to Join</Button>
            </CardActions>
        </Card>
    )
}

interface ListingParams { }

export function ListingPage() {
    const params = useParams<ListingParams>();
    const [joinSong, setJoinSong] = useState<SongData | null>(null);
    const listing = useSongListing();

    return (
        <>
            <JoinDialog song={joinSong} />
            <Box display="flex" flexDirection="column" alignItems="stretch" flexGrow="1">
                <DoubleContentBrick
                    marginBottom={5}
                    first={
                        <TextBlock
                            marginY={3}
                            pretitle="OpenChoir"
                            title="About Us"
                            subtitle="OpenChoir is developed by a group of people who believes that music will help us get through in these moments of social distancing."
                        />
                    }
                    second={
                        <ImageBlock
                            imgStyles={{ maxWidth: "100%" }}
                            source="illus6.png"
                        />
                    }
                    variant="lightblueTop"
                />
                <Container>
                    <Box marginY={4}>
                        <Typography color="primary" gutterBottom variant="h4">Recent Songs</Typography>
                    </Box>
                    <Grid container spacing={5}>
                        {listing.result && listing.result.filter(s => !!s.data).map(s => (
                            <Grid key={s.id} item xs={12} sm={6} md={4} lg={3}>
                                <SongCard song={s.data as SongData} onClick={() => setJoinSong(s.data)} />
                            </Grid>
                        ))}
                    </Grid>
                </Container>
                <FooterSection />
            </Box>
        </>
    );
}