import { Box, Container, Dialog, DialogContent, Grid, Typography } from '@material-ui/core';
import React, { useState, useCallback } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { AuthWall } from '../auth';
import { VoiceRawVideo } from '../buffering';
import { Background, MainBar } from '../layout';
import { Entity, fetchVoiceListing, SongData, useSongData, VoiceData } from "../metadata";
import { usePromise } from '../utils';
import { SectionSubMenu, VoiceCard } from './common';

interface SongParams {
    song: string;
}

function LoadedPage(props: { songId: string, song: Entity<SongData>, voices: Entity<VoiceData>[] }) {
    const history = useHistory();
    
    const onSelect = useCallback((voice: VoiceData) => {
        history.push(`/s/${voice.songRef?.id}/mix/${voice.createdBy.uid}/${voice.attemptId}`);
    }, []);

    return (
        <>
            <Box paddingY={4} justifyContent="flex-start">
                <Typography variant="h5" color="textSecondary">{props.song.data?.channelName}</Typography>
                <Typography variant="h4" color="textPrimary">{props.song.data?.name}</Typography>
            </Box>
            <SectionSubMenu activeSection="recordings" />
            <Box paddingY={4} display="flex" justifySelf="stretch">
                <Grid container spacing={3}>
                    {props.voices.map(v => (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <VoiceCard voice={v} onSelect={() => onSelect(v.data as VoiceData)} showActions />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
}

export function ListMixPage() {
    const params = useParams<SongParams>();
    const song = useSongData(params.song);

    const [voices, error] = usePromise(() => fetchVoiceListing(params.song), [params.song]);

    return (
        <Background variant="lightblueTop">
            <MainBar />
            <Container maxWidth="lg" style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                <AuthWall>
                    {song.result?.data && voices && <LoadedPage songId={params.song} song={song.result} voices={voices} />}
                </AuthWall>
            </Container>
        </Background >
    );
}