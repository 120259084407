import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useParams } from "react-router-dom";
import { useSongData, SongData, VoiceData } from "./metadata";
import { Link } from 'react-router-dom';
import { Container, Box, Typography, CardMedia, Card, CardContent, CardActions, Button, Grid, Slider, Switch } from '@material-ui/core';
import firebase from 'firebase/app';
import { useCallForward, CallHolder, useCallHolder } from 'react-callforward';
import { Background, MainBar } from './layout';


interface SongParams {
    song: string;
}

interface VoiceMixPrefs {
    volume: number;
    include: boolean;
}

interface KludgeVoiceData {
    fullPath: string;
    downloadUrl: string;
    allMetadata: any;
    userEmail?: string;
    userDisplayName?: string;
    userUid?: string;
    part?: string;
    recordedOn: string;
    attemptId?: string;
    contentType: string;
    mixPrefs: VoiceMixPrefs;
}

type Mix = Record<string, KludgeVoiceData>;

function parseAttemptId(path: string): string | undefined {
    const parts = path.split('-');
    let relevant = parts.length > 1 ? parts[parts.length - 1] : undefined;
    relevant = relevant && relevant.replace(".raw", "");
    return relevant;
}

async function loadVoice(ref: firebase.storage.Reference): Promise<KludgeVoiceData> {
    const metadata = await ref.getMetadata();
    const downloadUrl = await ref.getDownloadURL();

    return {
        fullPath: ref.fullPath,
        allMetadata: metadata,
        userEmail: metadata.customMetadata?.userEmail,
        userDisplayName: metadata.customMetadata?.userDisplayName,
        userUid: metadata.customMetadata?.userUid,
        part: metadata.customMetadata?.partKey,
        recordedOn: metadata.timeCreated,
        attemptId:  parseAttemptId(ref.fullPath),
        contentType: metadata.contentType,
        downloadUrl,
        mixPrefs: {
            volume: 3,
            include: true,
        },
    };
}

async function loadMix(songId: string): Promise<Mix> {
    const result = await firebase.storage().ref(songId).listAll()

    const loads = result.items
        .filter(i => i.name.startsWith('voice-'))
        .filter(i => !i.name.endsWith('.flac'))
        .filter(i => !i.name.endsWith('.audio'))
        .filter(i => !i.name.endsWith('.jpg'))
        //.filter(i => !i.name.endsWith('.raw'))
        .map(i => loadVoice(i));

    const voices = await Promise.all(loads);
    const entries = voices.map(v => ([v.fullPath, v]));

    return Object.fromEntries(entries);
}

function usePromise<T>(executor: () => Promise<T>, deps: any[]): [T | undefined, Error | undefined] {
    const [result, setResult] = useState<T>();
    const [error, setError] = useState<Error>();

    useEffect(() => {
        executor().then(setResult).catch(setError);
    }, deps);

    return [result, error];
}

interface VoiceCardProps {
    voice: KludgeVoiceData;
    song: SongData;
    onPrefsChange: (prefs: VoiceMixPrefs) => void;
    doPlay?: CallHolder<() => void>
}

function inferFileExtension(contentType: string): string {
    if (contentType.startsWith('video/webm')) {
        return "webm";
    } else if (contentType.startsWith('video/x-matroska')) {
        return "mkv";
    } else {
        return "xxx";
    }
}

function VoiceCard(props: VoiceCardProps) {
    const videoEl = useRef<HTMLVideoElement>(null);
    const params = useParams<SongParams>();

    useCallHolder(() => {
        if (videoEl.current) {
            videoEl.current.play();
        }
    }, props.doPlay)

    // const onVolumeChange = useCallback((evt: React.ChangeEvent<{}>, value: number | number[]) => {
    //     props.onPrefsChange({
    //         ...props.voice.mixPrefs,
    //         volume: value as number,
    //     });
    // }, [props.voice.mixPrefs]);

    // const onIncludeChange = useCallback((evt: React.ChangeEvent<{}>, value: boolean) => {
    //     props.onPrefsChange({
    //         ...props.voice.mixPrefs,
    //         include: value,
    //     });
    // }, [props.voice.mixPrefs]);

    const onPrintMix = useCallback(() => {
        console.log(JSON.stringify(({ [props.voice.fullPath]: props.voice })));
    }, [props.voice]);

    // const onDelete = useCallback(() => {
    //     const ref = firebase.storage().ref(props.voice.fullPath);
    //     ref.delete();
    // }, [props.voice]);


    const filename = useMemo(() => {
        const extension = inferFileExtension(props.voice.contentType);
        return `${props.voice.userDisplayName?.toLowerCase()}.${extension}`;
    }, [props.voice]);

    const onUpdateDisposition = useCallback(() => {
        const ref = firebase.storage().ref(props.voice.fullPath);
        ref.updateMetadata({ contentDisposition: `attachment; filename=${filename}` });
    }, [filename]);

    const voiceDoc = useMemo(() => {
        const id = `${params.song}-${props.voice.userUid}-${props.voice.attemptId}`;
        return firebase.firestore().collection("voices").doc(id);
    }, [props.voice.attemptId, params.song, props.voice.userUid]);

    console.log(voiceDoc.path);

    const [voiceSnapshot, err] = usePromise(async () => {
        return await voiceDoc.get();
    }, [voiceDoc]);

    const onCreateVoiceRecord = useCallback(() => {
        const fileRef = firebase.storage().ref(props.voice.fullPath);
        
        const data: VoiceData = {
            createdBy: {
                displayName: props.voice.userDisplayName || null,
                email: props.voice.userEmail || null,
                uid: props.voice.userUid || "",
            },
            phase: "raw",
            archived: false,
            attemptId: props.voice.attemptId || "unknown",
            createdOn: props.voice.recordedOn,
            songName: props.song.name,
            songRef: firebase.firestore().collection("songs").doc(params.song),
            choirName: props.song.choirName || "unknown",
            partKey: props.voice.part || "unknown",
            storageUrl: fileRef.toString(),
            drift: 0,
        };

        voiceDoc.set(data);
    }, [props.voice, props.song, params]);

    return (
        <Card>
            <CardMedia>
                <video ref={videoEl} style={{ width: "100%", height: "200px", backgroundColor: "#444" }} controls>
                    <source src={props.voice.downloadUrl} />
                </video>
            </CardMedia>
            <CardContent>
                <Typography color="textSecondary" gutterBottom>
                    {props.voice.part}
                </Typography>
                <Typography variant="h5" component="h2">
                    {props.voice.userDisplayName}
                </Typography>
                <Typography color="textSecondary">
                    {props.voice.recordedOn}
                </Typography>
                <Typography color="textSecondary">
                    {props.voice.contentType}
                </Typography>
                <Typography color="textSecondary">
                    {filename}
                </Typography>
                <Typography color="textSecondary">
                    {props.voice.attemptId}
                </Typography>
                <Typography color="textSecondary">
                    voice exists:
                    {voiceSnapshot !== undefined ? (voiceSnapshot.exists ? "yes" : "no") : "checking..."}
                </Typography>
                {/* <Slider
                    value={props.voice.mixPrefs.volume}
                    onChangeCommitted={onVolumeChange}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={0}
                    max={5}
                />
                <Switch
                    checked={props.voice.mixPrefs.include}
                    onChange={onIncludeChange}
                /> */}
            </CardContent>
            <CardActions>
                <Button href={props.voice.downloadUrl} size="small" disableElevation color="secondary" variant="contained">Download</Button>
                <Button onClick={onUpdateDisposition} size="small" disableElevation color="default" variant="contained">Disposition</Button>
                <Button onClick={onPrintMix} size="small" disableElevation color="default" variant="contained">Print Mix</Button>
                <Button onClick={onCreateVoiceRecord} size="small" disableElevation color="default" variant="contained">Create Voice</Button>
                {/* <Button onClick={onDelete} size="small" disableElevation color="default" variant="contained">Delete</Button> */}
            </CardActions>
        </Card>
    )
}

function LoadedPage(props: { song: SongData, mix: Mix }) {
    const [newMix, setNewMix] = useState<Mix>(props.mix);

    const onPrefsChange = useCallback((voice: KludgeVoiceData, newPrefs: VoiceMixPrefs) => {
        setNewMix(old => ({
            ...old,
            [voice.fullPath]: {
                ...old[voice.fullPath],
                mixPrefs: newPrefs,
            },
        }));
    }, []);

    return (
        <Background variant="lightblueTop">
            <MainBar />
            <Container maxWidth="lg" style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                <Box paddingY={4} justifyContent="flex-start">
                    <Typography variant="h5" color="textSecondary">{props.song.channelName}</Typography>
                    <Typography variant="h4" color="textPrimary">{props.song.name}</Typography>
                </Box>
                <Grid container spacing={2}>
                    {Object.values(newMix).map(voice => (
                        <Grid key={voice.fullPath} item xs={12} sm={6} md={4} lg={3}>
                            <VoiceCard song={props.song} voice={voice} onPrefsChange={(prefs) => onPrefsChange(voice, prefs)} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Background>
    );
}

export function MixPage() {
    const params = useParams<SongParams>();
    const song = useSongData(params.song);

    const [mix, error] = usePromise(() => loadMix(params.song), [params.song]);

    return (
        <>
            {song.result?.data && mix && <LoadedPage song={song.result.data} mix={mix} />}
        </>
    );
}