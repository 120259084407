import * as firebase from 'firebase/app';
import { useEffect, useState } from 'react';


function handleNextState(setState: SetState, snapshot: firebase.storage.UploadTaskSnapshot) {
    setState(old => ({
        ...old,
        progress:  (snapshot.bytesTransferred / snapshot.totalBytes),
        taskState: snapshot.state,
        isRunning: (snapshot.state == firebase.storage.TaskState.RUNNING),
        isComplete: (snapshot.state == firebase.storage.TaskState.SUCCESS),
    }));
}

function handleError(setState: SetState, error: any) {
    // A full list of error codes is available at
    // https://firebase.google.com/docs/storage/web/handle-errors
    console.error(error);

    setState(old => ({
        ...old,
        errorCode: error?.code,
        isRunning: false,
        isComplete: false,
        isCanceled: (error?.code == 'storage/canceled'),
    }));
}

function handleComplete(setState: SetState) {
    setState(old => ({
        ...old,
        isRunning: false,
        isComplete: true,
        isCanceled: false,
    }));
}

interface UploaderState {
    progress?: number;
    taskState?: firebase.storage.TaskState;
    errorCode?: string;
    isRunning?: boolean;
    isComplete?: boolean;
    isCanceled?: boolean;
}

type SetState = React.Dispatch<React.SetStateAction<UploaderState>>;

export function useFirebaseUploader(source?: Blob, target?: firebase.storage.Reference, contentType?: string, customMetadata?: Record<string, string>) {
    const [state, dispatch] = useState<UploaderState>({});

    useEffect(() => {
        if (!source || !target) return;

        console.log("rendering uploader");

        const task = target.put(source, { contentType, customMetadata });

        task.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            (s) => handleNextState(dispatch, s),
            (e) => handleError(dispatch, e),
            () => handleComplete(dispatch)
        );

    }, [source, target, contentType, customMetadata]);

    return state;
}