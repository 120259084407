import React, { useEffect, useState, useCallback, useMemo, useRef, createContext, PropsWithChildren, useContext } from 'react';
import { VoiceRecordingPage } from './recording';
import { createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core';
import { BrowserRouter as Router, Switch, Route, useParams } from "react-router-dom";

import * as firebase from 'firebase/app';

// These imports load individual services into the firebase namespace.
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';

import { FirebaseAuthProvider } from './auth';
import { SongPage } from './browsing';
import { PracticePage } from './practice';
import { MixPage } from './legacymix';
import { HomePage, AboutPage, ConcertPage, LivePage, PreflightPage, Edition1Page, Edition2Page, InterhousePage } from './landings';
import { ListingPage } from './browsing/listing';
import { NewSongPage } from './provisioning';
import { NewChoirPage } from './provisioning/choir';
import { AudioMixPage, VideoMixPage, ListMixPage, OutputMixPage, VoiceMixPage } from './mixing';
import { AdminPage } from './admin';
import { ConcertSignupPage } from './provisioning/concert';

firebase.initializeApp({
    apiKey: "AIzaSyCUPH4SePjwI3yKDpXEtWL96JL-7-SI0i4",
    authDomain: "openchoir-6d98c.firebaseapp.com",
    databaseURL: "https://openchoir-6d98c.firebaseio.com",
    projectId: "openchoir-6d98c",
    storageBucket: "openchoir-6d98c.appspot.com",
    messagingSenderId: "738036487324",
    appId: "1:738036487324:web:aca69ffe3587503b044959",
    measurementId: "G-X34LLG192B"
});

const theme = responsiveFontSizes(createMuiTheme({
    palette: {
        primary: {
            main: "#346E93",
        },
    },
    typography: {
        fontFamily: "Google Sans"
    }
}));

function App() {
    useEffect(() => {
        firebase.analytics().setAnalyticsCollectionEnabled(true);
    }, []);

    return (
        <Router>
            <FirebaseAuthProvider persistence={firebase.auth.Auth.Persistence.SESSION}>
                <ThemeProvider theme={theme}>
                    <Switch>
                        <Route path="/s/:song/v/:part/sing">
                            <VoiceRecordingPage />
                        </Route>
                        <Route path="/s/:song/v/:part/practice">
                            <PracticePage />
                        </Route>
                        <Route path="/s/:song/legacymix">
                            <MixPage />
                        </Route>
                        <Route path="/s/:song/output">
                            <OutputMixPage />
                        </Route>
                        <Route path="/s/:song/audio">
                            <AudioMixPage />
                        </Route>
                        <Route path="/s/:song/video">
                            <VideoMixPage />
                        </Route>
                        <Route path="/s/:song/mix/:user/:attempt">
                            <VoiceMixPage />
                        </Route>
                        <Route path="/s/:song/mix">
                            <ListMixPage />
                        </Route>
                        <Route path="/s/:song/admin">
                            <AdminPage />
                        </Route>
                        <Route path="/s/:song">
                            <SongPage />
                        </Route>
                        <Route path="/songs">
                            <ListingPage />
                        </Route>
                        <Route path="/newchoir">
                            <NewChoirPage />
                        </Route>
                        <Route path="/newsong">
                            <NewSongPage />
                        </Route>
                        <Route path="/about" exact>
                            <AboutPage />
                        </Route>
                        <Route path="/live" exact>
                            <LivePage />
                        </Route>
                        <Route path="/preflight" exact>
                            <PreflightPage />
                        </Route>
                        <Route path="/concert-signup">
                            <ConcertSignupPage />
                        </Route>
                        <Route path="/concert-1st-edition" exact>
                            <Edition1Page />
                        </Route>
                        <Route path="/bedes-choir-interhouse" exact>
                            <InterhousePage />
                        </Route>
                        <Route path="/concert" exact>
                            <Edition2Page />
                        </Route>
                        <Route path="/" exact>
                            <HomePage />
                        </Route>
                    </Switch>
                </ThemeProvider>
            </FirebaseAuthProvider>
        </Router>
    );
}

export default App;
