
const ALPHABET = '23456789abdegjkmnpqrvwxyz';

export function generateRandomSuffix(length: number) {
    var rtn = '';
    for (var i = 0; i < length; i++) {
        rtn += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
    }
    return rtn;
};

const SLUG_FROM = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
const SLUG_TO = "aaaaaeeeeeiiiiooooouuuunc------"

export function slugify(text: string): string {
    const normalized = text
        .split('')
        .map((letter, i) => letter.replace(new RegExp(SLUG_FROM.charAt(i), 'g'), SLUG_TO.charAt(i)))
        .toString()
        .toLowerCase()                  // Convert the string to lowercase letters
        .trim()                         // Remove whitespace from both sides of a string
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/&/g, '-y-')           // Replace & with 'and'
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-');        // Replace multiple - with single -

    const suffix = generateRandomSuffix(6);

    return `${normalized}-${suffix}`;
}