import { Button, Paper, Typography, Container, CardHeader, CardActions, CardContent, Card, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions } from "@material-ui/core";
import firebase from "firebase/app";
import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useCallback, useContext, useEffect, useState } from "react";

type AuthState = firebase.User | null | undefined;

type AuthStateUsage = [AuthState, Dispatch<SetStateAction<AuthState>>]

const CONTEXT = createContext<AuthStateUsage | undefined>(undefined);

async function runAsyncInitialization(persistence: firebase.auth.Auth.Persistence, dispatch: React.Dispatch<React.SetStateAction<AuthState>>) {
    firebase.auth().onAuthStateChanged(user => {
        dispatch(user);
    });

    await firebase.auth().setPersistence(persistence);
    await firebase.auth().getRedirectResult();
}

export function FirebaseAuthProvider(props: PropsWithChildren<{ persistence: firebase.auth.Auth.Persistence }>) {
    const [state, dispatch] = useState<AuthState>(undefined);

    useEffect(() => {
        runAsyncInitialization(props.persistence, dispatch);
    }, []);

    return (
        <CONTEXT.Provider value={[state, dispatch]}>
            {props.children}
        </CONTEXT.Provider>
    )
}

export function useLoginCallback() {
    return useCallback(() => {
        var provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithRedirect(provider);
    }, []);
}

function LoginDialog() {
    const onLogin = useLoginCallback();

    return (
        <Dialog open={true} maxWidth="xs" fullWidth>
            <DialogTitle>Login</DialogTitle>
            <DialogContent>
                <DialogContentText>To continue you need to login</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" variant="contained" onClick={onLogin}>Continue with Google</Button>
            </DialogActions>
        </Dialog>
    );
}

export function useLoggedInUser(): AuthState {
    const authUsage = useContext(CONTEXT);
    if (!authUsage) throw new Error("No auth provider in hierarchy");
    const [auth, _] = authUsage;
    return auth;
}

export function AuthWall(props: PropsWithChildren<{}>) {
    const user = useLoggedInUser();
    return (
        <>
            {user === null && <LoginDialog />}
            {!!user && props.children}
        </>
    );
}

