import React from 'react';
import { Box, Typography, CircularProgress } from '@material-ui/core';

export interface MediaPlaceHolderProps {
    style?: React.CSSProperties;
    message?: string;
    loading?: boolean;
}

export function MediaPlaceHolder(props: MediaPlaceHolderProps) {
    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" bgcolor="#000000" style={props.style}>
            {props.loading && <CircularProgress size={30} style={{ color: "#ffffff"}} />}
            {props.message && <Typography style={{ color: "#ffffff" }}>{props.message}</Typography> }
        </Box>
    );
}