import React, { useMemo, useRef } from 'react';
import * as firebase from 'firebase/app';
import { useEffect, useState } from 'react';
import { useCallHolder, CallHolder } from 'react-callforward';
import { string } from 'yup';
import { VoiceData } from './metadata';
import { CircularProgress, Box } from '@material-ui/core';
import { MediaPlaceHolder } from './uikit';
import { usePromise } from './utils';

export interface BufferingState {
    progress?: number;
    error?: any;
    isRunning?: boolean;
    isComplete?: boolean;
    data?: Blob | ArrayBuffer;
}

export interface DownloaderHandle extends BufferingState {
    objectUrl?: string;
    urlError: any;
    start: () => void;
}

type SetState = React.Dispatch<React.SetStateAction<BufferingState>>;

function handleXHRProgress(setState: SetState, xhr: XMLHttpRequest, evt: ProgressEvent) {
    setState(old => ({
        ...old,
        isComplete: false,
        isRunning: true,
        progress: evt.lengthComputable ? (evt.loaded / evt.total) : undefined,
    }));
}

function handleXHRLoad(setState: SetState, xhr: XMLHttpRequest, evt: ProgressEvent) {
    setState(old => ({
        ...old,
        isComplete: true,
        isRunning: false,
        data: xhr.response,
    }));
}

function triggerXHRDownload(url: string, dispatch: SetState, responseType: XMLHttpRequestResponseType) {
    var xhr = new XMLHttpRequest();
    xhr.responseType = responseType;
    xhr.onprogress = (evt) => handleXHRProgress(dispatch, xhr, evt);
    xhr.onload = (evt) => handleXHRLoad(dispatch, xhr, evt);
    xhr.open('GET', url);
    xhr.send();
}


export function getVoiceAssetPath(songId: string, userUid: string, attemptId: string, extension: string): string {
    return `${songId}/voice-${userUid}-${attemptId}.${extension}`;
}

export function getVoiceAssetPathFromData(voice: VoiceData | null, extension: string): string | null {
    if (!voice || !voice.songRef?.id || !voice.createdBy.uid || !voice.attemptId) return null;
    return getVoiceAssetPath(voice.songRef?.id, voice.createdBy.uid, voice.attemptId, extension);
}

export function useFirebaseDownloader(path: string | null, responseType: XMLHttpRequestResponseType, autoStart: boolean = true): DownloaderHandle {
    const [state, dispatch] = useState<BufferingState>({});
    const [start, setStart] = useState<boolean>(autoStart);
    const [url, urlError] = useFirebaseDownloadUrl(path);

    useEffect(() => {
        if (!url || !start) return;
        triggerXHRDownload(url, dispatch, responseType);
    }, [url, start]);

    const objectUrl = useMemo(() => {
        if (!state.data || responseType == "arraybuffer") return;
        return URL.createObjectURL(state.data);
    }, [responseType, state.data]);

    return useMemo(() => ({
        ...state,
        urlError,
        objectUrl,
        start: () => setStart(true),
    }), [state, url, responseType]);
}

export function useFirebaseDownloadUrl(path: string | null) {
    return usePromise(async () => {
        if (!path) return undefined;
        const ref = firebase.storage().ref(path);
        return await ref.getDownloadURL() as string;
    }, [path]);
}

interface FirebaseImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    path: string;
}

export function FirebaseImage(props: FirebaseImageProps) {
    const imgEl = useRef<HTMLImageElement>(null);
    const [url, error] = useFirebaseDownloadUrl(props.path);

    return (
        <img {...props} ref={imgEl} src={url || undefined} />
    )
}


export function FirebaseVideo(props: { path: string, style?: React.CSSProperties, doPlay?: CallHolder<() => void>, doPause?: CallHolder<() => void> }) {
    const videoEl = useRef<HTMLVideoElement>(null);

    const [url, setUrl] = useState<string>();

    useEffect(() => {
        const ref = firebase.storage().ref(props.path);
        ref.getDownloadURL().then(setUrl);
    }, [props.path]);

    useCallHolder(() => videoEl.current?.play(), props.doPlay);
    useCallHolder(() => videoEl.current?.pause(), props.doPause);

    return (
        <video ref={videoEl} style={props.style} controls>
            {!!url && <source src={url} />}
        </video>
    )
}

export function VoiceRawVideo(props: { voice: VoiceData | null, style?: React.CSSProperties }) {
    const assetPath = getVoiceAssetPathFromData(props.voice, "raw");

    if (!assetPath) {
        return <MediaPlaceHolder message="no video available" />;
    }

    return <FirebaseVideo path={assetPath} style={props.style} />;
}

interface VoiceSnapshotProps {
    style?: React.CSSProperties;
    voice: VoiceData | null;
    onClick?: () => void;
}

export function VoiceSnapshot(props: VoiceSnapshotProps) {
    const assetPath = getVoiceAssetPathFromData(props.voice, "snapshot.0.jpg");

    if (!assetPath) {
        return <MediaPlaceHolder message="no snapshot available" />;
    }

    return <FirebaseImage path={assetPath} style={props.style} onClick={props.onClick} />;
}

export function BufferedFirebaseVideo(props: { path: string, style: React.CSSProperties, doPlay: CallHolder<() => void>, doPause: CallHolder<() => void> }) {
    const videoEl = useRef<HTMLVideoElement>(null);

    const download = useFirebaseDownloader(props.path, "blob");

    useCallHolder(() => videoEl.current?.play(), props.doPlay);
    useCallHolder(() => videoEl.current?.pause(), props.doPause);

    return (
        <video ref={videoEl} style={props.style}>
            {!!download.objectUrl && <source src={download.objectUrl} />}
        </video>
    )
}
