import { Box, Card, CardHeader, CardMedia, Grid, Typography, CardContent, Avatar, Button, Link } from '@material-ui/core';
import firebase from "firebase";
import React, { useState } from 'react';
import { FooterSection, MainBar, Section, SingleContentBrick, TextBlockProps } from '../layout';
import { Entity, SongData, mapToEntity } from '../metadata';
import { useInterval, usePromise } from '../utils';
import choirs from './choirs.json';

const DEADLINE = 1593711075447;
//const DEADLINE = Date.now() + 10 * 1000;
const DEADLINE_DELTA = 90*60*1000;
const BROADCAST_URL = "https://youtu.be/WFclcXGm9ZA";

function CountdownNumber(props: { value?: number, caption: string }) {
    return (
        <Box marginX={1} bgcolor="#f50057" color="#ffffff" flexShrink={0} width={80} display="flex" alignItems="center" flexDirection="column" borderRadius={5}>
            <Typography variant="h3">{props.value}</Typography>
            <Typography variant="caption">{props.caption}</Typography>
        </Box>
    );
}

const MINUTE_IN_MS = (1000 * 60);
const HOUR_IN_MS = MINUTE_IN_MS * 60;
const DAY_IN_MS = HOUR_IN_MS * 24;

type CountdownState = [number, number, number, number];

function Countdown(props: { diff: number | undefined }) {
    if (!props.diff) return null;

    if (props.diff <= 0) {
        return <Button color="secondary" variant="contained" size="large" style={{ fontSize: 24 }} href={BROADCAST_URL} target="_blank">Play</Button>
    }

    const days = Math.floor(props.diff / DAY_IN_MS);
    const daysReminder = props.diff % DAY_IN_MS;
    const hours = Math.floor(daysReminder / HOUR_IN_MS);
    const hoursReminder = daysReminder % HOUR_IN_MS;
    const minutes = Math.floor(hoursReminder / MINUTE_IN_MS);
    const minutesReminder = hoursReminder % MINUTE_IN_MS;
    const seconds = Math.floor(minutesReminder / 1000);

    return (
        <>
            {/* <CountdownNumber value={days} caption="days" /> */}
            <CountdownNumber value={hours} caption="hours" />
            <CountdownNumber value={minutes} caption="minutes" />
            <CountdownNumber value={seconds} caption="seconds" />
        </>
    );
}

function YouTubeEmbed(props: { videoUrl: string }) {
    const fixedEmbedUrl = props.videoUrl.replace("https://youtu.be/", "https://www.youtube.com/embed/");
    return (
        <iframe width="100%" height="315" src={fixedEmbedUrl} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    );
}

function SongCard(props: { song: Entity<SongData>, diff: number | undefined }) {
    const videoUrl = props.song.data?.adhoc?.youtube as string;
    const isReady = !!props.diff && props.diff <= -DEADLINE_DELTA && !!videoUrl;

    return (
        <Card elevation={3}>
            <CardHeader title={props.song.data?.name} subheader={props.song.data?.choirName} />
            <CardMedia>
                { !isReady && <img src="gridbg.webp" style={{ width: "100%" }} /> }
                { !!isReady && videoUrl && <YouTubeEmbed videoUrl={videoUrl} /> }
            </CardMedia>
            <CardContent>
                <Typography variant="subtitle2">conducted by: {props.song.data?.adhoc?.ConductedBy}</Typography>
                { !isReady && <Typography color="secondary" variant="subtitle1">coming soon!</Typography> }
            </CardContent>
        </Card>
    )
}

interface ChoirCardProps extends TextBlockProps {
    title: string;
    subtitle: string;
    imageSrc?: string | null;
}

function ChoirCard(props: ChoirCardProps) {
    return (
        <Box display="flex" flexDirection="column" alignItems="center" marginY={5}>
            {!!props.imageSrc && <img style={{ height: 100, width: 100, objectFit: "contain" }} src={`concert2020/${props.imageSrc}`} />}
            {!props.imageSrc && <Avatar style={{ height: 100, width: 100 }} />}
            <Typography variant="subtitle1" style={{ textAlign: "center" }}>{props.title}</Typography>
            <Typography variant="subtitle2" color="textSecondary">{props.subtitle}</Typography>
        </Box>
    );
}

export async function fetchSongListing(): Promise<Entity<SongData>[]> {
    const raw = await firebase.firestore()
        .collection("songs")
        .where("adhoc.concert2020", "==", true)
        .get();

    return raw.docs.map(raw => mapToEntity<SongData>(raw));
}


export function PreflightPage() {
    const [songs, error] = usePromise(() => fetchSongListing(), []);
    const [diff, setDiff] = useState<number>();

    useInterval(() => {
        const diff = DEADLINE - Date.now();
        setDiff(diff);
    }, 200);

    return (
        <Section variant="lightblueTop">
            <MainBar />
            <SingleContentBrick display="flex" alignItems="center" containerMaxWidth="md" marginY={3}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="h2" style={{ color: "#ffffff" }} align="center">OpenChoir In Concert</Typography>
                    <Typography variant="h5" color="textSecondary" align="center">Join us on July 2nd in an online event where more than a 1000 voices, from 46 choirs and 6 different countries, will showcase their songs in a one-of-a-kind choral concert.</Typography>
                </Box>
            </SingleContentBrick>
            <SingleContentBrick display="flex" alignItems="center" containerMaxWidth="md">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Box height="30vw" width="50vw" display="flex" alignItems="center" justifyContent="center" style={{ backgroundImage: "url(gridbg.webp)", backgroundSize: "cover" }}>
                        <Countdown diff={diff} />
                    </Box>
                </Box>
            </SingleContentBrick>

            <Section
                marginY={10}
                header={<Typography color="primary" gutterBottom variant="h4" style={{ textAlign: "center" }}>When & Where</Typography>}
                children={<SingleContentBrick display="flex" alignItems="center" containerMaxWidth="md">
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography align="center" variant="h6" color="textSecondary">
                            Concert will be presented via <strong>YouTube</strong> live stream<br />
                            at the following link: <Link color="secondary" target="_blank" href={BROADCAST_URL}>{BROADCAST_URL}</Link>
                            <br />
                            <br />
                            <strong>19 hs</strong>: Argentina, Brasil, Uruguay<br />
                            <strong>18 hs</strong>: Chile, Venezuela<br />
                            <strong>17 hs</strong>: México, Perú, Ecuador, Colombia<br />
                            <strong>16 hs</strong>: El Salvador
                    </Typography>
                    </Box>
                </SingleContentBrick>}
            />

            <Section
                marginY={8}
                header={<Typography color="primary" gutterBottom variant="h4" style={{ textAlign: "center" }}>Participating Choirs</Typography>}
                children={
                    <SingleContentBrick >
                        <Grid container spacing={3}>
                            {choirs.map((s, i) => <Grid item xs={6} sm={4} md={3} lg={2}> <ChoirCard key={i} title={s.title} subtitle={s.country} imageSrc={s.logo} /> </Grid>)}
                        </Grid>
                    </SingleContentBrick>
                }
            />

            <Section
                marginY={8}
                header={<Typography color="primary" gutterBottom variant="h4" style={{ textAlign: "center" }}>Featured Songs</Typography>}
                children={
                    <SingleContentBrick paddingY={3}>
                        <Grid container spacing={3}>
                            {songs && songs.map(s => <Grid item xs={12} sm={6} md={4} lg={4}> <SongCard key={s.id} song={s} diff={diff} /> </Grid>)}
                        </Grid>
                    </SingleContentBrick>
                }
            />

            <FooterSection />
        </Section>
    );
}