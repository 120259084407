import { Box, LinearProgress, Typography, Button, Slider, Grid, IconButton } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { SongData, SongPartData } from "./metadata";
import firebase from "firebase";
import { usePromise } from "./utils";
import { FeatureWall } from "./features";
import { CallHolder, useCallForward, useCallHolder } from "react-callforward";

import PlayIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";

export type BaseMediaMode = "custom-video" | "backing-track";

export function getBaseMediaModeForSongPart(partData?: SongPartData | null): BaseMediaMode {
    const rawDirectorMode = partData?.directorMode;
    switch (rawDirectorMode) {
        case "custom-video":
        case "backing-track":
            return rawDirectorMode as BaseMediaMode;
        default:
            return "backing-track";
    }
}

interface PartBaseMedia {
    path: string;
    mode: BaseMediaMode;
    url: string;
}

export async function inferPartBaseMedia(songId: string, songData: SongData, partKey: string): Promise<PartBaseMedia> {
    const partData = songData.parts[partKey];
    const mode = getBaseMediaModeForSongPart(partData);

    let path;
    switch (mode) {
        case "custom-video":
            path = `${songId}/${partKey}-director.mp4`;
            break;
        case "backing-track":
            path = `${songId}/soundtrack`;
            break;
    }

    const ref = firebase.storage().ref(path);
    const url = await ref.getDownloadURL();

    return { mode, path, url };
}

interface MediaViewProps {
    doPlay?: CallHolder<() => void>;
    doPause?: CallHolder<() => void>;
    doScrub?: CallHolder<(pos: number) => void>;
    onCanPlay?: (duration: number) => void;
    onProgress?: (position: number) => void;
    onPlay?: () => void;
    onPause?: () => void;
    onEnded?: () => void;
}

interface BackingTrackViewProps extends MediaViewProps {
    baseMediaUrl: string;
}

function BackingTrackView(props: BackingTrackViewProps) {
    const mediaEl = useRef<HTMLAudioElement | null>(null);

    const onCanPlay = useCallback(() => {
        if (!props.onCanPlay || !mediaEl.current?.duration) return;
        props.onCanPlay(mediaEl.current?.duration);
    }, [props.onCanPlay, mediaEl]);

    const onProgress = useCallback(() => {
        if (!props.onProgress || !mediaEl.current?.currentTime) return;
        props.onProgress(mediaEl.current.currentTime);
    }, [props.onProgress, mediaEl]);

    useCallHolder(() => {
        mediaEl.current?.play();
    }, props.doPlay, [mediaEl]);

    useCallHolder(() => {
        mediaEl.current?.pause();
    }, props.doPause, [mediaEl]);

    useCallHolder((pos: number) => {
        if (!mediaEl.current) return;
        mediaEl.current.currentTime = pos;
    }, props.doScrub, [mediaEl]);

    return (
        <>
            <Box width="100%" height={300} display="flex" alignItems="center" justifyContent="center" bgcolor="black">
                <Typography variant="subtitle1" style={{ color: "white" }}>Backing Track</Typography>
            </Box>
            <audio
                ref={mediaEl}
                style={{ width: 0, height: 0 }}
                onCanPlay={onCanPlay}
                onEnded={props.onEnded}
                onTimeUpdate={onProgress}
                onPlay={props.onPlay}
                onPause={props.onPause}>
                <source src={props.baseMediaUrl} />
            </audio>
        </>
    );
}

interface CustomVideoViewProps extends MediaViewProps {
    baseMediaUrl: string;
}

function CutomVideoView(props: CustomVideoViewProps) {
    const mediaEl = useRef<HTMLVideoElement | null>(null);

    const onCanPlay = useCallback(() => {
        if (!props.onCanPlay || !mediaEl.current?.duration) return;
        props.onCanPlay(mediaEl.current?.duration);
    }, [props.onCanPlay, mediaEl]);

    const onProgress = useCallback(() => {
        if (!props.onProgress || !mediaEl.current?.currentTime) return;
        props.onProgress(mediaEl.current.currentTime);
    }, [props.onProgress, mediaEl]);

    useCallHolder(() => {
        mediaEl.current?.play();
    }, props.doPlay, [mediaEl]);

    useCallHolder(() => {
        mediaEl.current?.pause();
    }, props.doPause, [mediaEl]);

    useCallHolder((pos: number) => {
        if (!mediaEl.current) return;
        mediaEl.current.currentTime = pos;
    }, props.doScrub, [mediaEl]);

    return (
        <FeatureWall required={['PlayH264']}>
            <video
                ref={mediaEl}
                style={{ width: "100%", backgroundColor: "#000000", height: "40vh", outline: "none" }}
                onCanPlay={onCanPlay}
                onEnded={props.onEnded}
                onTimeUpdate={onProgress}
                onPlay={props.onPlay}
                onPause={props.onPause}>
                <source src={props.baseMediaUrl} />
            </video>
        </FeatureWall>
    );
}

interface AnyBaseMediaProps extends MediaViewProps {
    baseMediaMode: BaseMediaMode;
    baseMediaUrl: string;
}

export function AnyBaseMediaView(props: AnyBaseMediaProps) {
    switch (props.baseMediaMode) {
        case "backing-track": return (
            <BackingTrackView {...props} />
        );
        case "custom-video": return (
            <CutomVideoView {...props} />
        );
    }
}

export function SongPartPlayer(props: { songId: string, songData: SongData, partKey: string }) {
    const [duration, setDuration] = useState<number>();
    const [position, setPosition] = useState<number>();
    const [playing, setPlaying] = useState<boolean>(false);

    const [baseMedia, error] = usePromise(async () => {
        return await inferPartBaseMedia(props.songId, props.songData, props.partKey);
    }, [props.songData, props.partKey]);

    const [onPlay, doPlay] = useCallForward([]);
    const [onPause, doPause] = useCallForward([]);
    const [onScrub, doScrub] = useCallForward([]);

    return (
        <Box display="flex" flexDirection="column" alignItems="stretch">
            {baseMedia && <AnyBaseMediaView
                baseMediaMode={baseMedia.mode}
                baseMediaUrl={baseMedia.url}
                onCanPlay={setDuration}
                onProgress={setPosition}
                doPlay={doPlay}
                doPause={doPause}
                doScrub={doScrub}
                onPlay={() => setPlaying(true)}
                onPause={() => setPlaying(false)}
            />}
            <MediaProgressBar
                playing={playing}
                position={position}
                duration={duration}
                onPlay={onPlay}
                onPause={onPause}
                onScrub={onScrub}
            />
        </Box>
    )
}

interface MediaProgressBarProps {
    playing: boolean;
    position?: number;
    duration?: number;
    onPlay: () => void;
    onPause: () => void;
    onScrub: (pos: number) => void;
}

export function MediaProgressBar(props: MediaProgressBarProps) {
    const value = props.position && Math.round(props.position * 100) || 0;
    const max = props.duration && Math.round(props.duration * 100) || 0;

    const onChange: (event: React.ChangeEvent<{}>, value: number | number[]) => void = (_, value) => {
        const newPos = (value as number) / 100;
        props.onScrub(newPos);
    };

    return (
        <Box display="flex" alignItems="center">
            <Box justifyContent="center" padding={1}>
                {!props.playing && <IconButton color="secondary" onClick={props.onPlay}><PlayIcon /></IconButton>}
                {props.playing && <IconButton color="secondary" onClick={props.onPause}><PauseIcon /></IconButton>}
            </Box>
            <Box justifyContent="center" padding={1} minWidth={40}>
                <Typography>{Math.round(value / 100)}s</Typography>
            </Box>
            <Box justifyContent="center" padding={1} flexGrow={1}>
                <Slider color="secondary" value={value} max={max} onChangeCommitted={onChange} />
            </Box>
            <Box justifyContent="center" padding={1} minWidth={40}>
                <Typography>{Math.round(max / 100)}s</Typography>
            </Box>
        </Box>
    );
}
