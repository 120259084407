import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, LinearProgress, MenuItem, Paper, Select, Step, StepLabel, Stepper, TextField, Typography } from '@material-ui/core';
import firebase from 'firebase';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AuthWall, useLoggedInUser } from '../auth';
import { FooterSection, MainBar, Section, SingleContentBrick, TextBlock } from '../layout';
import { SongData, useEntity, ChoirData } from '../metadata';
import { slugify } from './slugs';
import { useFormik } from 'formik';
import * as Yup from 'yup';

interface SetProps {
    state: NewChoirState;
    setState: SetNewSongState;
}

interface InfoFormValues {
    choirName: string;
    choirType: string;
    conductorName: string;
    country: string;
}

const InfoFormSchema = Yup.object({
    choirName: Yup.string().required(),
    choirType: Yup.string().required(),
    conductorName: Yup.string().required(),
    country: Yup.string().required(),
});

function InfoStep(props: SetProps) {
    const form = useFormik<InfoFormValues>({
        initialValues: { choirName: "", choirType: "", conductorName: "", country: "" },
        validationSchema: InfoFormSchema,
        onSubmit: () => { }
    });

    const onContinue = useCallback(() => {
        const choirId = slugify(form.values.choirName);
        props.setState(val => ({ ...val, ...form.values, choirId, step: 1 }))
    }, [form.values]);

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
                <TextField
                    name="choirName"
                    value={form.values.choirName}
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                    error={!!form.touched.choirName && !!form.errors.choirName}
                    helperText={!!form.touched.choirName && form.errors.choirName}
                    label="Choir Name"
                    fullWidth
                    variant="filled"
                />
            </Grid>
            <Grid item xs={12} md={8}>
                <TextField
                    select
                    name="choirType"
                    value={form.values.choirType}
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                    error={!!form.touched.choirType && !!form.errors.choirType}
                    helperText={!!form.touched.choirType && form.errors.choirType}
                    label="Choir Type"
                    variant="filled"
                    fullWidth
                >
                    <MenuItem value="adults">Adults</MenuItem>
                    <MenuItem value="teenagers">Teenagers</MenuItem>
                    <MenuItem value="children">Children</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={12} md={8}>
                <TextField
                    name="conductorName"
                    value={form.values.conductorName}
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                    error={!!form.touched.conductorName && !!form.errors.conductorName}
                    helperText={!!form.touched.conductorName && form.errors.conductorName}
                    label="Conductor Name"
                    fullWidth
                    variant="filled"
                />
            </Grid>
            <Grid item xs={12} md={8}>
                <TextField
                    name="country"
                    value={form.values.country}
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                    error={!!form.touched.country && !!form.errors.country}
                    helperText={!!form.touched.country && form.errors.country}
                    label="Country"
                    fullWidth
                    variant="filled"
                />
            </Grid>
            <Grid item xs={12} md={8}>
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    disabled={!form.isValid || !form.dirty}
                    onClick={onContinue}>
                        Continue
                </Button>
            </Grid>
        </Grid>
    );
}

function SongsStep(props: SetProps) {
    const [songs, setSongs] = useState<Record<string, { name: string, checked: boolean }>>({
        poder_suenos: { name: "El Poder de los Sueños (intermediate level)", checked: false },
        imagine: { name: "Imagine (advanced level)", checked: false },
    });

    const onCheckChange = useCallback((id: string, checked: boolean) => {
        setSongs(songs => ({ ...songs, [id]: { ...songs[id], checked } }));
    }, []);

    const valid = useMemo(() => {
        return Object.values(songs).some(s => !!s.checked);
    }, [songs]);

    const onContinue = useCallback(() => {
        const selected = Object.entries(songs).filter(entry => !!entry[1].checked).map(entry => entry[0]);
        props.setState(val => ({ ...val, songs: selected, step: 2 }));
    }, [songs]);

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
                <Typography variant="h6" color="textSecondary">Select the song (or songs) you want to sing. Each singer will participate by recording their part for one or more of these songs.</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
                <FormControl>
                    <FormGroup>
                        {Object.keys(songs).map(key => (
                            <FormControlLabel
                                key={key}
                                label={songs[key].name}
                                control={<Checkbox checked={songs[key].checked} onChange={(_, checked) => onCheckChange(key, checked)} name={key} />}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={8}>
                <Button variant="contained" color="secondary" size="large" disabled={!valid} onClick={onContinue}>Continue</Button>
            </Grid>
        </Grid>
    )
}

function FinishStep(props: SetProps) {
    const entity = useEntity("choirs", props.state.choirId as string);
    const [done, setDone] = useState<boolean>(false);
    const auth = useLoggedInUser() as firebase.User;

    useEffect(() => {
        if (!entity.result) return;

        const data: ChoirData = {
            name: props.state.choirName,
            type: props.state.choirType,
            conductorName: props.state.conductorName,
            createdOn: new Date().toISOString(),
            country: props.state.country,
            createdBy: {
                uid: auth.uid,
                displayName: auth.displayName,
                email: auth.email,
            },
            adhoc: {
                concertSongs: props.state.songs
            }
        };

        entity.result.set(data, true).then(() => setDone(true));
    }, [props.state, entity.result]);

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
                <Box margin={4} flexGrow={1} display="flex" alignItems="center" justifyContent="center">
                    {!done && <CircularProgress />}
                    {done && <Typography variant="h5">Your song has been submitted. You'll receive an email when the song is ready to start recording.</Typography>}
                </Box>
            </Grid>
        </Grid>
    )
}

interface NewChoirState {
    step: number;
    choirId?: string;
    conductorName: string;
    choirName: string;
    choirType: string;
    country: string;
    songs: string[];
}

type SetNewSongState = React.Dispatch<React.SetStateAction<NewChoirState>>;

const EMPTY_CHOIR_STATE: NewChoirState = {
    step: 0,
    choirName: "",
    choirType: "",
    conductorName: "",
    country: "",
    songs: [],
};

export function NewChoirPage() {
    const [state, setState] = useState<NewChoirState>(EMPTY_CHOIR_STATE);

    return (
        <Section variant="lightblueTop">
            <MainBar homeHref="/concert" />
            <SingleContentBrick
                children={
                    <TextBlock
                        marginY={3}
                        title="New Choir"
                        subtitle="Let's setup a new choir, we need some information"
                    />
                }
            />
            <SingleContentBrick paddingY={1}>
                <Paper elevation={2} style={{ flexGrow: 1 }}>
                    <Stepper activeStep={state.step}>
                        <Step>
                            <StepLabel>Info</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Songs</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Finish</StepLabel>
                        </Step>
                    </Stepper>
                    <Box padding={5}>
                        <AuthWall>
                            {state.step == 0 && <InfoStep state={state} setState={setState} />}
                            {state.step == 1 && <SongsStep state={state} setState={setState} />}
                            {state.step == 2 && <FinishStep state={state} setState={setState} />}
                        </AuthWall>
                    </Box>
                </Paper>
            </SingleContentBrick>
            <FooterSection />
        </Section>
    );
}