import React, { PropsWithChildren, useState, useEffect } from "react";
import { Paper, Typography } from "@material-ui/core";

function checkVideoFormatPlayable(type: string) {
    const video = document.createElement('video')
    const result = video.canPlayType(type);
    return result == "probably";
}


type FeatureKey = "MediaRecorder" | "AudioContext" | "PlayOgg" | "PlayH264" | "PlayWebm" | "PlayVp9";

async function checkFeature(feature: FeatureKey): Promise<boolean> {
    switch (feature) {
        case "MediaRecorder":
            return !!window.MediaRecorder;
        case "AudioContext":
            return !!window.AudioContext;
        case "PlayOgg":
            return checkVideoFormatPlayable('video/ogg; codecs="theora"');
        case "PlayH264":
            return checkVideoFormatPlayable('video/mp4; codecs="avc1.42E01E, mp3"');
        case "PlayWebm":
            return checkVideoFormatPlayable('video/webm; codecs="vp8, vorbis"');
        case "PlayVp9":
            return checkVideoFormatPlayable('video/webm; codecs="vp9"');
    }
}

async function checkFeatures(features: FeatureKey[]): Promise<boolean> {
    const checks = features.map(f => checkFeature(f));
    const results = await Promise.all(checks);
    return results.every(result => result === true);
}

interface FeatureWallProps {
    required: FeatureKey[];
    wall?: React.ReactElement;
    loading?: React.ReactElement;
}

function DefaultWall() {
    return (
        <Paper style={{ padding: 10 }}>
            <Typography variant="body1">
                <p>Sorry, you browser doesn't support the required features. Please use one of the following:</p>
                <ul>
                    <li>Chrome on Laptop/Desktop Computer</li>
                    <li>Chrome on Android Phones</li>
                    <li>Firefox on Laptop/Desktop Computer</li>
                </ul>
            </Typography>
        </Paper>
    )
}


function DefaultLoading() {
    return (
        <p>Checking for browser feature</p>
    )
}

export function FeatureWall(props: PropsWithChildren<FeatureWallProps>) {
    const [available, setAvailable] = useState<boolean>();

    useEffect(() => {
        checkFeatures(props.required).then(setAvailable);
    }, [props.required]);

    return (
        <>
            {available && props.children}
            {available === false && (props.wall || <DefaultWall />)}
            {available === undefined && (props.loading || <DefaultLoading />)}
        </>
    )
}