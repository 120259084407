import { Box, Container, Dialog, DialogContent, Grid, Typography, TableBody, TableRow, TableCell, Table, Paper, Switch, TableHead } from '@material-ui/core';
import React, { useState, useCallback } from 'react';
import { useParams } from "react-router-dom";
import { AuthWall } from './auth';
import { VoiceRawVideo, FirebaseImage, VoiceSnapshot } from './buffering';
import { Background, MainBar } from './layout';
import { Entity, fetchVoiceListing, SongData, useSongData, VoiceData } from "./metadata";
import { usePromise } from './utils';


interface SongParams {
    song: string;
}

function VoiceRow(props: { voice: Entity<VoiceData> }) {
    const [authorized, setAuthorized] = useState<boolean>(props.voice.data?.adhoc?.authorized);
    const onAuthorizedChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        props.voice.update({ adhoc: { authorized: checked }});
        setAuthorized(checked);
    }, [props.voice]);

    return (
        <TableRow>
            <TableCell>
                <VoiceSnapshot style={{ width: 100, height: 100, objectFit: "cover" }} voice={props.voice.data || null} />
            </TableCell>
            <TableCell>{props.voice.data?.createdBy.displayName}</TableCell>
            <TableCell>{props.voice.data?.createdBy.email}</TableCell>
            <TableCell>{props.voice.data?.createdOn}</TableCell>
            <TableCell>{props.voice.data?.attemptId}</TableCell>
            <TableCell><Switch checked={authorized} onChange={onAuthorizedChange} /></TableCell>
        </TableRow>
    )
}

function LoadedPage(props: { songId: string, song: Entity<SongData>, voices: Entity<VoiceData>[] }) {
    return (
        <>
            <Box paddingY={4} justifyContent="flex-start">
                <Typography variant="h5" color="textSecondary">{props.song.data?.channelName}</Typography>
                <Typography variant="h4" color="textPrimary">{props.song.data?.name}</Typography>
            </Box>
            <Box paddingY={4} display="flex" justifyContent="stretch" justifySelf="stretch">
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                            <TableCell>Snapshot</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Created On</TableCell>
                            <TableCell>Attempt Id</TableCell>
                            <TableCell>Authorized</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.voices.filter(v => !v.data?.archived).map(v => <VoiceRow key={v.id} voice={v} />)}
                        </TableBody>
                    </Table>
                </Paper>
            </Box>
        </>
    );
}

export function AdminPage() {
    const params = useParams<SongParams>();
    const song = useSongData(params.song);

    const [voices, error] = usePromise(() => fetchVoiceListing(params.song), [params.song]);

    return (
        <Background variant="lightblueTop">
            <MainBar />
            <Container maxWidth="lg" style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                <AuthWall>
                    {song.result?.data && voices && <LoadedPage songId={params.song} song={song.result} voices={voices} />}
                </AuthWall>
            </Container>
        </Background >
    );
}